import {Outlet} from "react-router-dom";
import Header from "../components/layout/Header";
import Chart from "../components/form/Chart";
import Slugger from "../services/form/Slugger";

export default function Root(){
    return <>
        <div className="flex flex-col h-screen w-full overflow-x-hidden">
            <Header/>
            <Outlet/>

            {/* IMPORTANT USED FOR PDF */}
            <canvas className="hidden" id="canvas"></canvas>

            <div className="hidden">
                <Chart
                    renderOnly
                    name={Slugger.slugify('PieChart')}
                />
            </div>

        </div>
    </>
}

import {PDFFactoryColors, PDFFactoryFontSizes, PDFFactoryMargins, PDFFactoryNoAnswerText} from "../PDFFactory";
import {RGB} from "pdf-lib";
import {LocalStorage} from "../LocalStorage";
import PDFFactoryEntryType from "../../../types/pdf/PDFFactoryEntryType";
import {OrderedMap} from "immutable";
import MultiSelectEntryType from "../../../types/form/MultiSelectEntryType";
import Utils from "../../Utils";
import SchoolData from "../../../assets/schools.json";

function* RenderStep6(): Generator<PDFFactoryEntryType> {

    const values = {
        opleidingAnders: LocalStorage.get("lobcv_answers_orientation_wat-verwacht-ik-dat-op-mijn-vervolgopleiding-anders-zal-zijn-dan-in-mijn-huidige-opleiding") ?? PDFFactoryNoAnswerText,
        lesuren: LocalStorage.get("Lesuren") ?? "0",
        reistijd: LocalStorage.get("Reistijd") ?? "0",
        zelfstudie: LocalStorage.get("Zelfstudie") ?? "0",
        bijbaan: LocalStorage.get("Bijbaantje") ?? "0",
        ontspanning: LocalStorage.get("Ontspanning") ?? "0",
        sporten: LocalStorage.get("Sporten") ?? "0",
        huishouden: LocalStorage.get("Huishouden") ?? "0",
        zorgtaken: LocalStorage.get("Zorgtaken") ?? "0",
        slapen: LocalStorage.get("Slapen") ?? "0",
        anders: LocalStorage.get("Anders") ?? "0"
    }

    if(Object.values(values).filter(x => {
        if(!isNaN(parseInt(x))) return parseInt(x) > 0
        return x.length > 0
    }).length <= 0) return

    yield {
        type: "header",
        text: "Verwachtingen en voorbereiding",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.green as RGB,
        image: "//" + window.location.host + "/assets/images/expectation-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}

    if(values.opleidingAnders){
        yield {
            type: "text",
            text: "Wat verwacht ik dat op mijn vervolgopleiding anders zal zijn dan in mijn huidige opleiding?",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.opleidingAnders,
            size: PDFFactoryFontSizes.smaller
        }
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Hoeveel uur per week denk ik straks kwijt te zijn aan:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}

    yield {
        type: "header",
        text: `Lesuren: ${values.lesuren} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Lesuren.png",
    }

    yield {
        type: "pie",
        size: 260,
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Reistijd: ${values.reistijd} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Reistijd.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Zelfstudie: ${values.zelfstudie} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Zelfstudie.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Bijbaantje: ${values.bijbaan} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Bijbaantje.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Ontspanning: ${values.ontspanning} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Ontspanning.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Sporten: ${values.sporten} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Sporten.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Huishouden: ${values.huishouden} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Huishouden.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Zorgtaken: ${values.zorgtaken} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Zorgtaken.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Slapen: ${values.slapen} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Slapen.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.medium}

    yield {
        type: "header",
        text: `Anders: ${values.anders} uur`,
        size: PDFFactoryFontSizes.normal,
        image: "//" + window.location.host + "/assets/png/Anders.png",
    }



}

export default RenderStep6

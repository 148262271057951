import {FormEvent} from "react";
import {RxCross2} from "react-icons/rx";

type RadioButtonProps = {
    name: string,
    value: string,
    rows?: number,
    onChange: (e: FormEvent<HTMLTextAreaElement>) => void
}

const TextareaInputField = (props: RadioButtonProps) => {
    return <>
        <textarea
            rows={props.rows ?? 3}
            name={props.name}
            id={props.name}
            onChange={props.onChange}
            value={props.value}
            className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent w-full min-h-16"></textarea>
    </>
}

export default TextareaInputField;

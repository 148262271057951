import React, {useState} from "react";
import CheckboxField from "./common/CheckboxField";
import HorizontalInput from "./layout/HorizontalInput";
import TextInputField from "./common/TextInputField";
import TextareaInputField from "./common/TextareaInputField";
import {RxPlus, RxTrash} from "react-icons/rx";
import {List, OrderedMap} from "immutable";
import MultiSelectEntryType from "../../types/form/MultiSelectEntryType";
import {LocalStorage} from "../../services/form/LocalStorage";

type MultiSelectInputOptionPropsType = {
    label: string,
    name: string
}

type MultiSelectInputOptionStateType = {
    readonly checked: boolean,
    readonly entries: OrderedMap<string, MultiSelectEntryType>
}

const MultiSelectInputOptionState = {
    default: (entriesStorageName: string, checkedStorageName: string): MultiSelectInputOptionStateType => {

        const checked = LocalStorage.get(checkedStorageName) === "true"

        const storageValue: OrderedMap<string, MultiSelectEntryType> = LocalStorage.get(entriesStorageName) ?
            OrderedMap<string, MultiSelectEntryType>(JSON.parse(LocalStorage.get(entriesStorageName) as string)) :
            (OrderedMap<string, MultiSelectEntryType>())

        const currentTimestamp = (new Date()).getTime().toString()

        return ({
            checked: checked,
            entries: storageValue.count() > 0 ?
                storageValue :
                storageValue.set(currentTimestamp, {
                    id: currentTimestamp,
                    details: "",
                    program: "",
                    school: ""
                })
        })
    },
    updaters: {
        setChecked: (checked: boolean, name: string) => (state: MultiSelectInputOptionStateType) => {
            LocalStorage.set(name, checked.toString())

            return {...state, checked: checked}
        },
        addEmptyEntry: (storageName: string) => (state: MultiSelectInputOptionStateType) => {
            const key = (new Date()).getTime().toString()

            if (state.entries.count() >= 10)
                return state

            const entry: MultiSelectEntryType = {
                id: key,
                details: "",
                program: "",
                school: ""
            }

            const storageValue: OrderedMap<string, MultiSelectEntryType> = LocalStorage.get(storageName) ?
                OrderedMap<string, MultiSelectEntryType>(JSON.parse(LocalStorage.get(storageName) as string)) :
                (OrderedMap<string, MultiSelectEntryType>()).set(key, entry)

            LocalStorage.set(storageName, JSON.stringify(storageValue.set(key, entry).toJS()))

            return {
                ...state,
                entries: state.entries.set(key, entry)
            }
        },
        removeEntry: (key: string, storageName: string) => (state: MultiSelectInputOptionStateType) => {
            const storageValue: OrderedMap<string, MultiSelectEntryType> = LocalStorage.get(storageName) ?
                OrderedMap<string, MultiSelectEntryType>(JSON.parse(LocalStorage.get(storageName) as string)) :
                (OrderedMap<string, MultiSelectEntryType>())

            LocalStorage.set(storageName, JSON.stringify(storageValue.remove(key).toJS()))

            return {
                ...state,
                entries: state.entries.remove(key)
            }
        },
        updateEntry: (key: string, name: string, storageName: string, value: string) => (state: MultiSelectInputOptionStateType) => {
            const entry = state.entries.get(key)

            if (entry === undefined){
                console.log(1)
                return state
            }

            const storageValue: OrderedMap<string, MultiSelectEntryType> = LocalStorage.get(storageName) ?
                OrderedMap<string, MultiSelectEntryType>(JSON.parse(LocalStorage.get(storageName) as string)) :
                (OrderedMap<string, MultiSelectEntryType>()).set(key, entry)

            if (!storageValue.has(key))
                return state

            LocalStorage.set(storageName, JSON.stringify(storageValue.update(key, (entry) =>
                entry ? {
                    ...entry,
                    [name]: value
                } : entry
            ).toJS()))

            return {
                ...state,
                entries: state.entries.update(key, (entry) =>
                    entry ? {
                        ...entry,
                        [name]: value
                    } : entry
                )
            }
        }
    }
}

export default function MultiSelectInputOption(props: MultiSelectInputOptionPropsType) {
    const entriesName = `${props.name}[entries]`

    const [state, setState] = useState(MultiSelectInputOptionState.default(entriesName, `${props.name}[checked]`))

    //TODO : remove this and replace it with sopmehting dynamic
    const temp_check = 'lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[andere-studiekeuzeactiviteiten-bijv-studiekeuzebeurs-zomerschool-etc]';

    return <>

        <div className="flex flex-col">

            <CheckboxField
                label={props.label}
                name={props.name}
                value={"1"}
                checked={state.checked}
                onChange={(e) => {
                    setState(MultiSelectInputOptionState.updaters.setChecked(e.currentTarget.checked, `${props.name}[checked]`))
                }}
            />

            {
                !state.checked ?
                    null :
                    state.entries.valueSeq().map((entry, index) => (
                        <>
                            <div key={`${entry.id}-${index}`} className="mb-6 flex flex-col">

                                <div className="ms-8">
                                    <HorizontalInput
                                        labelTop
                                        label={props.name !== temp_check ? "Welke onderwijsinstelling" : "Beschrijving activiteit"}>
                                        <>
                                            <TextareaInputField
                                                rows={2}
                                                name={"1"}
                                                value={entry.school}
                                                onChange={(e) => {
                                                    setState(MultiSelectInputOptionState.updaters.updateEntry(entry.id, "school", entriesName, e.currentTarget.value))
                                                }}
                                            />
                                        </>
                                    </HorizontalInput>
                                </div>

                                {
                                    props.name !== temp_check ?
                                        <div className="ms-8">
                                            <HorizontalInput
                                                labelTop
                                                label="Welke opleiding">
                                                <>
                                                    <TextareaInputField
                                                        rows={2}
                                                        name={"1"}
                                                        value={entry.program}
                                                        onChange={(e) => {
                                                            setState(MultiSelectInputOptionState.updaters.updateEntry(entry.id, "program", entriesName, e.currentTarget.value))
                                                        }}
                                                    />
                                                </>
                                            </HorizontalInput>
                                        </div> : null
                                }


                                <div className="ms-8">
                                    <HorizontalInput
                                        labelTop
                                        label="Geef een min- en pluspunt van deze activiteit">
                                        <>
                                            <TextareaInputField
                                                rows={3}
                                                name={"1"}
                                                value={entry.details}
                                                onChange={(e) => {
                                                    setState(MultiSelectInputOptionState.updaters.updateEntry(entry.id, "details", entriesName, e.currentTarget.value))
                                                }}
                                            />
                                        </>
                                    </HorizontalInput>
                                </div>

                                {
                                    state.entries.count() > 1 ?
                                        <div className="ms-auto">

                                            <button
                                                onClick={() => {
                                                    setState(MultiSelectInputOptionState.updaters.removeEntry(entry.id, entriesName))
                                                }}>
                                                <RxTrash
                                                    className="h-8 w-8 text-lobcv-blue-600 p-1 bg-white rounded-full hover:bg-lobcv-blue-300 transition"/>
                                            </button>

                                        </div> : null
                                }

                            </div>
                        </>
                    ))
            }

            {
                state.checked && state.entries.count() < 10 ?
                    <div className="ms-auto">

                        <button onClick={() => {
                            setState(MultiSelectInputOptionState.updaters.addEmptyEntry(entriesName))
                        }}>
                            <RxPlus
                                className="h-8 w-8 text-lobcv-blue-600 p-1 bg-white rounded-full hover:bg-lobcv-blue-300 transition"/>
                        </button>

                    </div> : null
            }

        </div>

    </>
}

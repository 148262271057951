import HorizontalInput from "./layout/HorizontalInput";
import React, {useState} from "react";
import InputStateType from "../../types/form/InputStateType";
import {LocalStorage} from "../../services/form/LocalStorage";

type RangeInputState = InputStateType

const RangeInputState = {
    default: (name: string): RangeInputState => ({value: LocalStorage.get(name)}),
    updaters: {
        setValue: (name: string, value: string) => (state: RangeInputState): RangeInputState => {
            LocalStorage.set(name, value);
            return {...state, value: value};
        }
    }
}

type RangeInputPropsType = {
    label: string,
    name: string,
    min: number,
    max: number,
    step: number
}

export default function RangeInput(props: RangeInputPropsType) {

    const [state, setState] = useState<RangeInputState>(RangeInputState.default(props.name));

    const getPercentage = (value: number) => Math.round(((value - props.min) / (props.max - props.min)) * 100);

    return <>
        <HorizontalInput equalHalves label={props.label}>
            <>
                <div className="flex w-full h-full relative">
                    <input
                        value={state.value ?? 50}
                        onChange={(e) => setState(RangeInputState.updaters.setValue(props.name, e.target.value))}
                        className={
                            "w-full value-" + getPercentage(parseInt(state.value ?? '50'))
                        }
                        type="range"
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        name={props.name}
                    />
                </div>
            </>
        </HorizontalInput>
    </>
}
import {inputNamePrefixer} from "../../utils/form/FormUtils";
import ChapterLayout from "../../components/chapters/ChapterLayout";
import ChapterImage from "../../components/chapters/ChapterImage";
import ChapterTitle from "../../components/chapters/ChapterTitle";
import ChapterText from "../../components/chapters/ChapterText";
import Paging from "../../components/chapters/Paging";
import RangeInput from "../../components/form/RangeInput";
import HorizontalInput from "../../components/form/layout/HorizontalInput";
import React, {useEffect, useState} from "react";
import ChapterSkeleton from "../../components/chapters/ChapterSkeleton";
import ChapterContentPart from "../../components/chapters/ChapterContentPart";
import {ChapterContentState, ChapterContentStateRepository} from "../../states/chapters/ChapterConentState";
import LocalChapterContent from "../../services/form/LocalChapterContent";

export default function SkillsAndCompetencies() {


    const [state, setState] =
        useState<ChapterContentState>(ChapterContentStateRepository.default());

    useEffect(() => {
        // Define the async function
        const fetchData = async () => {
            try {
                // Simulate async operation (e.g., fetch API)
                const response = await LocalChapterContent.getChapterContent('SkillsAndCompetencies');

                // Populate state with the result
                setState(ChapterContentStateRepository.updates.setChapterContent(response));
            } catch (err: any) {
                // Handle errors
                console.error(err.message || 'An error occurred');
                setState(ChapterContentStateRepository.updates.setLoading(false));
            } finally {
                // Set loading to false after the async operation is complete
                setState(ChapterContentStateRepository.updates.setLoading(false));
            }
        };

        // Call the async function
        fetchData();
    }, []);

    return <>
        <ChapterLayout
            step={3}
            title="Vaardigheden en kwaliteiten"
            color="aqua"
            image={<ChapterImage src="/assets/images/skills-and-competencies-icon.png"/>}
            paging={
                <Paging
                    nextTo="/studiekeuzeproces-en-orientatie"
                    prevTo="/aanvullende-informatie"
                    nextStep="naar stap 4"
                />
            }
        >

            <>
                {
                    state.loading ?
                        <ChapterSkeleton/> :
                        <>
                            {
                                state.chapterContent.map((contentPart) => (
                                    <ChapterContentPart
                                        key={contentPart.label}
                                        prefixer={inputNamePrefixer('personal_information_')}
                                        chapterContentPart={contentPart}/>
                                ))
                            }
                        </>
                }
            </>

        </ChapterLayout>
    </>
}

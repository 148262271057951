import {PDFFactoryColors, PDFFactoryFontSizes, PDFFactoryMargins, PDFFactoryNoAnswerText} from "../PDFFactory";
import {RGB} from "pdf-lib";
import {LocalStorage} from "../LocalStorage";
import PDFFactoryEntryType from "../../../types/pdf/PDFFactoryEntryType";
import {OrderedMap} from "immutable";
import MultiSelectEntryType from "../../../types/form/MultiSelectEntryType";
import Utils from "../../Utils";

function* RenderStep4(): Generator<PDFFactoryEntryType> {

    const values = {
        openDays: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[open-dagen][entries]") ?? null,
        openDaysChecked: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[open-dagen][checked]") === "true",
        trailStudying: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[proefstuderen][entries]") ?? null,
        trailStudyingChecked: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[proefstuderen][checked]") === "true",
        experienceDay: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[meeloopdagen][entries]") ?? null,
        experienceDayChecked: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[meeloopdagen][checked]") === "true",
        otherActivities: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[andere-studiekeuzeactiviteiten-bijv-studiekeuzebeurs-zomerschool-etc][entries]") ?? null,
        otherActivitiesChecked: LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholenuniversiteiten-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[andere-studiekeuzeactiviteiten-bijv-studiekeuzebeurs-zomerschool-etc][checked]") === "true",
        people: Utils.chunk(getPeople(), 4),
        otherPeople: LocalStorage.get('lobcv_answers_orientation_met-wie-bespreek-ik-mijn-studiekeuzeproces[other]') ?? PDFFactoryNoAnswerText,
        wieIsBelangrijk: LocalStorage.get("lobcv_answers_orientation_wie-is-voor-mij-het-meest-belangrijk-bij-mijn-studiekeuze-en-waarom") ?? PDFFactoryNoAnswerText,
        besteAdvies: LocalStorage.get("lobcv_answers_orientation_wat-is-het-beste-advies-dat-ik-heb-ontvangen") ?? PDFFactoryNoAnswerText,
        besteHulp: LocalStorage.get("lobcv_answers_orientation_wie-of-wat-heeft-mij-het-beste-geholpen-bij-mijn-studiekeuze-en-waarom") ?? PDFFactoryNoAnswerText
    }

    if (Object.values(values).filter(x => {
        if (x === null) return false
        if (typeof x === 'boolean') return x
        return x.length > 0
    }).length <= 0) return

    yield {
        type: "header",
        text: "Studiekeuzeproces en -oriëntatie",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.yellow as RGB,
        image: "//" + window.location.host + "/assets/images/orientation-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}

    if (values.openDaysChecked && values.openDays) {
        yield {
            type: "text",
            text: "Open dagen",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield* renderActivity(values.openDays);
    }

    if (values.trailStudyingChecked && values.trailStudying) {
        yield {
            type: "text",
            text: "Proefstuderen",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield* renderActivity(values.trailStudying);
    }

    if (values.experienceDayChecked && values.experienceDay) {
        yield {
            type: "text",
            text: "Meeloopdagen",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield* renderActivity(values.experienceDay);
    }

    if (values.otherActivitiesChecked && values.otherActivities) {
        yield {
            type: "text",
            text: "Andere studiekeuzeactiviteiten",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield* renderActivity(values.otherActivities);
    }

    if (values.people.length > 0 || values.otherPeople) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Met wie bespreek ik mijn studiekeuzeproces:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
    }

    if (values.people.length > 0) {
        for (const peopleChunk of values.people) {
            yield {type: "margin", size: PDFFactoryMargins.smaller}
            yield {
                type: 'columns',
                col1: peopleChunk[0] ?? PDFFactoryNoAnswerText,
                col2: peopleChunk[1] ?? PDFFactoryNoAnswerText,
                col3: peopleChunk[2] ?? PDFFactoryNoAnswerText,
                col4: peopleChunk[3] ?? PDFFactoryNoAnswerText,
                bold: false,
                size: PDFFactoryFontSizes.normal
            }
        }
    }

    if (values.otherPeople) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Anders, namelijk:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.otherPeople,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.wieIsBelangrijk) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "wie is voor mij het meest belangrijk bij mijn studiekeuze en waarom?",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.wieIsBelangrijk,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.besteAdvies) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Wat is het beste advies dat ik heb ontvangen?",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.besteAdvies,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.besteHulp) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Wie of wat heeft mij het beste geholpen bij mijn studiekeuze en waarom?",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.besteHulp,
            size: PDFFactoryFontSizes.smaller
        }
    }

}

function* renderActivity(activities: string): Generator<PDFFactoryEntryType> {
    const activityMap =
        OrderedMap<string, MultiSelectEntryType>(JSON.parse(activities))

    for (const openDay of activityMap.valueSeq()) {

        if (!openDay.school && !openDay.program && !openDay.details) continue

        if (openDay.school) {
            yield {
                type: "text",
                text: "Welke onderwijsinstelling",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {
                type: "text",
                text: openDay.school,
                size: PDFFactoryFontSizes.smaller
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
        }

        if (openDay.program) {
            yield {
                type: "text",
                text: "Welke opleiding",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {
                type: "text",
                text: openDay.program,
                size: PDFFactoryFontSizes.smaller
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
        }

        if (openDay.details) {
            yield {
                type: "text",
                text: "Geef een min- en pluspunt van deze activiteit",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {
                type: "text",
                text: openDay.details,
                size: PDFFactoryFontSizes.smaller
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
}

const getPeople = () => {
    const people: Record<string, string> = JSON.parse(LocalStorage.get('lobcv_answers_orientation_met-wie-bespreek-ik-mijn-studiekeuzeproces[options]') ?? '{}') as Record<string, string>
    return Object
        .keys(people)
        .filter(x => people.hasOwnProperty(x) ? people[x] : false)
        .map(x => x.replaceAll('-', ' '))
        .map(x => x.charAt(0).toUpperCase() + x.slice(1))
}

export default RenderStep4

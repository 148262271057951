import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import Home from "./routes/Home";
import Faq from "./routes/Faq";
import About from "./routes/About";
import Mentor from "./routes/Mentor";
import NotFound from "./routes/NotFound";
import Root from "./routes/Root";
import SkillsAndCompetencies from "./routes/chapters/SkillsAndCompetencies";
import Empty from "./routes/chapters/Empty";


import PersonalInformation from "./routes/chapters/PersonalInformation";
import AdditionalInformation from "./routes/chapters/AdditionalInformation";
import Orientation from "./routes/chapters/Orientation";
import Motivation from "./routes/chapters/Motivation";
import Expectation from "./routes/chapters/Expectation";
import Closing from "./routes/chapters/Closing";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route index element={<Home/>} ErrorBoundary={NotFound} />
            {/*<Route path="/veelgestelde-vragen"  element={<Faq/>}/>*/}
            <Route path="/over-het-lob-cv"  element={<About/>}/>
            <Route path="/begeleiders"  element={<Mentor/>}/>
            <Route element={<Root/>}>
                <Route path="/persoonlijke-informatie" element={<PersonalInformation/>}/>
                <Route path="/aanvullende-informatie" element={<AdditionalInformation/>}/>
                <Route path="/vaardigheden-en-kwaliteiten" element={<SkillsAndCompetencies/>}/>
                <Route path="/studiekeuzeproces-en-orientatie" element={<Orientation/>}/>
                <Route path="/motivatie-en-drijfveren" element={<Motivation/>}/>
                <Route path="/verwachtingen" element={<Expectation/>}/>
                <Route path="/bedankt" element={<Closing/>}/>
            </Route>
        </>
    )
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

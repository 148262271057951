import Header from "../components/layout/Header";
import {Link} from "react-router-dom";
import {Outlet} from "react-router-dom";

export default function About() {

    return <>
        <div className="flex flex-col h-screen w-full">
            <Header/>
            <main>
                <div className="bg-lobcv-gray-400 py-5">
                    <div className="container lg:max-w-[960px] mx-auto px-6">
                        <div className="flex">
                            <div className="flex justify-center align-middle flex-col w-6/12 ">
                                <h1 className="w-full text-white text-3xl lg:text-4xl font-bold flex-grow-0">
                                    404
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-12">
                    <div className="container lg:max-w-[960px] mx-auto px-6">
                        <div className="flex gap-10">
                            <div className="flex justify-center align-middle flex-col">
                                <h2 className="text-2xl lg:text-3xl font-bold mb-4">
                                    Pagina niet gevonden</h2>

                                <p className="mb-3">
                                    <strong>Je kunt hieronder terug gaan!</strong>
                                </p>

                                <p className="mb-3">
                                    <Link to={'/'} className="text-lobcv-purple-500">Terug naar de
                                        homepage</Link>
                                </p>
                                <p className="mb-3">
                                    <Link to={'/persoonlijke-informatie'}  className="text-lobcv-purple-500">
                                        Terug naar jouw CV
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Outlet/>
        </div>
    </>
}

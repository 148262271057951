import {Link, NavLink} from "react-router-dom";
import {AiOutlineClose, AiOutlineMenu} from "react-icons/ai";
import {useState} from "react";

const HeaderNavItem = (props: {to: string, children: string}) =>
    <Link to={props.to}
          className="uppercase ms-8 text-md font-semibold text-lobcv-blue-600 transition hover:text-lobcv-blue-700"
    >{props.children}</Link>

const HeaderMenuNavItem = (props: {to: string, children: string}) =>
    <Link to={props.to}
          className="uppercase my-4 text-4xl font-semibold text-lobcv-blue-600 transition hover:text-lobcv-blue-700"
    >{props.children}</Link>

type HeaderState = {
    menuOpen: boolean
}

const HeaderState = {
    default: () => ({menuOpen: false}),
    updaters: {
        openMenu: () => (state: HeaderState): HeaderState => ({...state, menuOpen: true}),
        closeMenu: () => (state: HeaderState): HeaderState => ({...state, menuOpen: false})
    }
}

export default function Header() {

    const [state, setState] = useState<HeaderState>(HeaderState.default())

    return <>

        <header className="bg-white z-50">
            <div className="container px-6 h-[4rem] lg:h-[5rem] mx-auto py-3 flex">
                <NavLink to="/">
                <img src="/assets/images/lobcv_logo.png" alt="LOB-cv" className="h-full"/>
                </NavLink>

                <nav className="hidden lg:flex ms-auto items-center">
                    <HeaderNavItem to="/">Home</HeaderNavItem>
                    {/*<HeaderNavItem to="/veelgestelde-vragen">FAQ</HeaderNavItem>*/}
                    <HeaderNavItem to="/over-het-lob-cv">Over het LOB-cv</HeaderNavItem>
                    <HeaderNavItem to="/begeleiders">Begeleiders</HeaderNavItem>
                </nav>

                <button
                    className={(state.menuOpen ? "hidden" : "") + " lg:hidden ms-auto h-full"}
                    onClick={() => setState(HeaderState.updaters.openMenu())}>
                    <AiOutlineMenu className="text-4xl"/>
                </button>
                <button
                    className={(!state.menuOpen ? "hidden" : "") + " lg:hidden ms-auto h-full"}
                    onClick={() => setState(HeaderState.updaters.closeMenu())}>
                    <AiOutlineClose className="text-4xl"/>
                </button>

            </div>
        </header>

        <div
            className={(!state.menuOpen ? "hidden" : "") + " fixed top-0 left-0 h-screen w-full z-40 pt-[4rem] flex items-center bg-white/80 backdrop-blur-sm"}>

            <nav className="flex flex-col w-full ms-auto items-center">
                <HeaderMenuNavItem to="/">Home</HeaderMenuNavItem>
                {/*<HeaderMenuNavItem to="/veelgestelde-vragen">FAQ</HeaderMenuNavItem>*/}
                <HeaderMenuNavItem to="/over-het-lob-cv">Over het LOB-cv</HeaderMenuNavItem>
                <HeaderMenuNavItem to="/begeleiders">Begeleiders</HeaderMenuNavItem>
            </nav>

        </div>

    </>
}

const ChapterSkeleton = () => <>
    <div role='status' className='animate-pulse w-full'>

        <div className="flex w-full flex-wrap">
            <div className="w-full lg:w-1/3">
                <p className='h-6 bg-gray-300 rounded-md w-48 mb-4'></p>
            </div>
            <div className="w-full lg:w-2/3">
                <p className='h-8 bg-gray-300 rounded-md w-full mb-4'></p>
            </div>
        </div>

        <>
            <div className="flex w-full flex-wrap">
                <div className="w-full lg:w-1/3">
                    <p className='h-6 bg-gray-300 rounded-md w-48 mb-4'></p>
                </div>
                <div className="w-full lg:w-2/3">
                    <p className='h-20 bg-gray-300 rounded-md w-full mb-4'></p>
                </div>
            </div>
        </>

        <div className="w-full mb-8">
            <p className='h-8 bg-gray-300 rounded-md w-48 mb-4'></p>
            <p className='h-4 bg-gray-300 rounded-full w-full mb-2'></p>
            <p className='h-4 bg-gray-300 rounded-full w-full mb-2'></p>
            <p className='h-4 bg-gray-300 rounded-full w-full mb-2'></p>
            <p className='h-4 bg-gray-300 rounded-full w-3/4 mb-2'></p>
        </div>


    </div>
</>

export default ChapterSkeleton

import {FormEvent} from "react";
import {RxCross2} from "react-icons/rx";

type RadioButtonProps = {
    label: string,
    name: string,
    value: string,
    onChange: (e: FormEvent<HTMLInputElement>) => void,
    checked?: boolean,
}

const CheckboxField = (props: RadioButtonProps) => {
    return <>
        <label
            title={props.label}
            className={styles.wrapper}>
            <input type="checkbox"
                   onChange={props.onChange}
                   value={props.value}
                   name={props.name}
                   checked={props.checked ?? false}
                   className="hidden peer"/>
            <span
                className={styles.checkbox}/>
            <span className={styles.checkmark}>
                <RxCross2 className={styles.checkmarkIcon} />
            </span>
            <span className={styles.label}>{props.label}</span>
        </label>
    </>
}

const styles = {
    wrapper: "inline-flex items-center me-4 cursor-pointer",
    checkbox: "h-6 w-6 bg-white border-lobcv-blue-600 border-2 rounded-md inline-flex items-center justify-center flex-shrink-0",
    checkmark: "h-6 w-6 rounded-full absolute items-center justify-center hidden peer-hover:inline-flex  peer-hover:opacity-40 peer-checked:!opacity-100 peer-checked:inline-flex",
    checkmarkIcon: "text-2xl text-lobcv-blue-600",
    label: "ml-2 text-lobcv-blue-600 text-lg truncate fles-shrink"
}


export default CheckboxField;

import {ChapterContentPartType} from "../../types/form/ChapterContentPartType";
import TextInput from "../form/TextInput";
import Slugger from "../../services/form/Slugger";
import ChapterTitle from "./ChapterTitle";
import ChapterText from "./ChapterText";
import ChapterList from "./ChapterList";
import ChapterListColumns from "./ChapterListColumns";
import RangeInput from "../form/RangeInput";
import SelectInput from "../form/SelectInput";
import MultiSelectInput from "../form/MultiSelectInput";
import Chart from "../form/Chart";
import HorizontalInput from "../form/layout/HorizontalInput";
import SelectEducationInput from "../form/SelectEducationInput";
import SelectSchoolInput from "../form/SelectSchoolInput";
import CheckboxesInput from "../form/CheckboxesInput";

const ChapterContentPart = (props: { chapterContentPart: ChapterContentPartType, prefixer: (x: string) => string }) => {

    const name = props.prefixer(Slugger.slugify(props.chapterContentPart.label));

    return <>
        {
            props.chapterContentPart.type === 'text' ?
                <TextInput label={props.chapterContentPart.label} name={name}/> : null
        }
        {
            props.chapterContentPart.type === 'date' ?
                <TextInput date label={props.chapterContentPart.label} name={name}/> : null
        }
        {
            props.chapterContentPart.type === 'textarea' ?
                <TextInput textarea label={props.chapterContentPart.label} name={name}/> : null
        }
        {
            props.chapterContentPart.type === 'textarea-vertical' ?
                <TextInput textarea vertical label={props.chapterContentPart.label} name={name}/> : null
        }
        {
            props.chapterContentPart.type === 'header' ?
                <ChapterTitle>{props.chapterContentPart.label}</ChapterTitle> : null
        }
        {
            props.chapterContentPart.type === 'paragraph' ?
                <ChapterText className="mb-4"><span
                    dangerouslySetInnerHTML={{__html: props.chapterContentPart.label}}></span></ChapterText> : null
        }
        {
            props.chapterContentPart.type === 'list' ?
                <ChapterList options={props.chapterContentPart.options ? props.chapterContentPart.options : []}
                             listTitle={props.chapterContentPart.listTitle}
                             className="mb-4">{props.chapterContentPart.label}</ChapterList> : null
        }
        {
            props.chapterContentPart.type === 'list-columns' ?
                <ChapterListColumns options={props.chapterContentPart.options ? props.chapterContentPart.options : []} listTitle={props.chapterContentPart.listTitle} className="mb-4">{props.chapterContentPart.label}</ChapterListColumns> : null
        }
        {
            props.chapterContentPart.type === 'range' ?
                <RangeInput
                    min={0}
                    max={100}
                    step={1}
                    label={props.chapterContentPart.label}
                    name={name}
                /> : null
        }
        {
            props.chapterContentPart.type === 'select' ?
                <SelectInput
                    label={props.chapterContentPart.label}
                    options={props.chapterContentPart.options ? props.chapterContentPart.options : []}
                    name={name}
                /> : null
        }
        {
            props.chapterContentPart.type === 'select-program' ?
                <SelectEducationInput
                    label={props.chapterContentPart.label}
                    name={name}
                /> : null
        }
        {
            props.chapterContentPart.type === 'select-school' ?
                <SelectSchoolInput
                    label={props.chapterContentPart.label}
                    name={name}
                /> : null
        }
        {
            props.chapterContentPart.type === 'multi-select' ?
                <MultiSelectInput
                    label={props.chapterContentPart.label}
                    options={props.chapterContentPart.options ? props.chapterContentPart.options : []}
                    name={name}
                /> : null
        }
        {
            props.chapterContentPart.type === 'range-header' ?
                <HorizontalInput
                    equalHalves={true}
                    label=""
                    name={name}>
                    <>
                        <div className="flex justify-between">
                            <ChapterText>{props.chapterContentPart.label.split('#')[0]}</ChapterText>
                            <ChapterText>{props.chapterContentPart.label.split('#')[1]}</ChapterText>
                        </div>
                    </>
                </HorizontalInput> : null
        }
        {
            props.chapterContentPart.type === 'pie' ?
                <Chart name={name}/> : null
        }
        {
            props.chapterContentPart.type === 'checkboxes' ?
                <CheckboxesInput name={name} label={props.chapterContentPart.label} options={props.chapterContentPart.options ?? []}/> : null
        }
    </>
}

export default ChapterContentPart

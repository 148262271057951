import ExportType from "../../types/form/ExportType";
import PDFFactoryEntryType from "../../types/pdf/PDFFactoryEntryType";
import SchoolData from "../../assets/schools.json";

import {PDFDocument, PDFPage, RGB} from 'pdf-lib'
import {
    PDFFactory,
    PDFFactoryColors,
    PDFFactoryFontSizes,
    PDFFactoryMargins,
    PDFFactoryNoAnswerText
} from "./PDFFactory";
import Slugger from "./Slugger";
import {LocalStorage} from "./LocalStorage";
import renderStep1and2 from "./step_exports/RenderStep1and2";
import renderStep4 from "./step_exports/RenderStep4";
import renderStep5 from "./step_exports/RenderStep5";
import renderStep6 from "./step_exports/RenderStep6";
import renderStep3 from "./step_exports/RenderStep3_";


const addTextToPage = (
    page: PDFPage,
    text: string,
    yOffset: number,
    size: number,
    options: object
) => {
    const margin = 10

    const newYOffset = yOffset - margin - size

    page.drawText(text, {
        ...options,
        y: newYOffset
    })

    return (text: string, size: number, options: object) =>
        addTextToPage(page, text, newYOffset, size, options)
}

function* getPdfContent(): Generator<PDFFactoryEntryType> {

    yield {
        type: "image",
        image: "//" + window.location.host + "/assets/images/lobcv_logo.png",
        size: 50
    }
    yield {
        type: "margin",
        size: PDFFactoryMargins.large / 2
    }
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_personal_information_naam") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.subheader,
        bold: true
    }
    yield {
        type: "margin",
        size: PDFFactoryMargins.large
    }

    yield * renderStep1and2()

    yield {type: "margin", size: PDFFactoryMargins.large}

    yield * renderStep3()

    yield {type: "margin", size: PDFFactoryMargins.large}

    yield * renderStep4()

    yield {type: "margin", size: PDFFactoryMargins.large}

    yield * renderStep5()

    yield {type: "margin", size: PDFFactoryMargins.large}

    yield * renderStep6()

}

const PDFExport: ExportType = {

    serve: async () => {

        window.dispatchEvent(new CustomEvent("lobcv:export:pdf:start"))

        const pdfDoc = await PDFFactory.generate([...getPdfContent()])

        const pdfBytes = await pdfDoc.save()
        const blob = new Blob([pdfBytes], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)

        window.open(url, '_lobcv')
        return
        
        // sleep 2 seconds
        await new Promise(resolve => setTimeout(resolve, 2000))

        const name = LocalStorage.get("lobcv_answers_personal_information_naam") ?? "pdf"

        // timestamp d-m-Y
        const date = new Date()
        const timestamp = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`

        // Create an anchor element and trigger a download
        const a = document.createElement('a');
        a.href = url;
        a.download = `lobcv-${Slugger.slugify(name)}-${timestamp}.pdf`; // The name of the file to be saved
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

}

export {PDFExport};

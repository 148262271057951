import {ColorTypes, PDFDocument, PDFPage, PDFPageDrawTextOptions, RGB} from "pdf-lib";
import fontkit from '@pdf-lib/fontkit'
import PDFFactoryEntryType from "../../types/pdf/PDFFactoryEntryType";

const xOffset = 50
const yOffset = 50

const PDFFactoryMargins = {
    large: 30,
    medium: 16,
    small: 10,
    smaller: 6,
}

const PDFFactoryFontSizes = {
    header: 22,
    subheader: 20,
    larger: 18,
    normal: 14,
    smaller: 12,
}

const PDFFactoryColors = {
    purple: {
        type: ColorTypes.RGB,
        red: 0.537,
        green: 0.396,
        blue: 0.694
    },
    blue: {
        type: ColorTypes.RGB,
        red: 0.278,
        green: 0.663,
        blue: 0.792
    },
    yellow: {
        type: ColorTypes.RGB,
        red: 0.984,
        green: 0.737,
        blue: 0.376
    },
    red: {
        type: ColorTypes.RGB,
        red: 0.874,
        green: 0.384,
        blue: 0.376
    },
    green: {
        type: ColorTypes.RGB,
        red: 0.243,
        green: 0.788,
        blue: 0.635
    },
    gray: {
        type: ColorTypes.RGB,
        red: 0.682,
        green: 0.780,
        blue: 0.792
    }
}

const PDFFactoryNoAnswerText = ""

const calcLines = (text: string, maxWidth: number, lineHeight: number, bold?: boolean): number => {
    const wrapper = document.createElement("div")
    wrapper.innerText = text
    wrapper.id = "calcLines"
    wrapper.style.width = maxWidth + "px"
    wrapper.style.fontFamily = "Lato"
    wrapper.style.fontWeight = bold ? "bold" : "normal"
    wrapper.style.lineHeight = lineHeight + "px"
    wrapper.style.pointerEvents = "none"
    wrapper.style.position = "absolute"
    wrapper.style.visibility = "hidden"
    wrapper.style.top = "0"
    wrapper.style.left = "0"
    document.body.appendChild(wrapper)
    const lines = wrapper.clientHeight / lineHeight
    document.getElementById("calcLines")?.remove()
    return Math.round(lines)
}

const addEntriesToDoc = async (pdfDoc: PDFDocument, y: number, entries: PDFFactoryEntryType[]) => {

    if (entries.length === 0) {
        return;
    }

    const entry = entries[0]

    // Add the first page if necessary
    if (pdfDoc.getPages().length === 0)
        pdfDoc.addPage()

    pdfDoc.registerFontkit(fontkit)

    const urlRegular = `//${window.location.host}/assets/fonts/Lato-Regular.ttf`
    const fontBytesRegular = await fetch(urlRegular).then(res => res.arrayBuffer())
    const fontRegular = await pdfDoc.embedFont(fontBytesRegular)

    const urlBold = `//${window.location.host}/assets/fonts/Lato-Bold.ttf`
    const fontBytesBold = await fetch(urlBold).then(res => res.arrayBuffer())
    const fontBold = await pdfDoc.embedFont(fontBytesBold)

    const {width, height} = pdfDoc.getPages()[0].getSize()

    const nextPositionOnPage = height - entry.size - y

    const trueSize =
        entry.type === "pie"
            ? 0 :
            entry.type === "text"
                ? calcLines(entry.text, width - xOffset * 2, entry.size * 1.1, entry.bold) * entry.size
                : entry.type === "columns"
                    ? calcLines(
                    [entry.col1 ?? "", entry.col2 ?? "", entry.col3 ?? "", entry.col4 ?? ""].reduce((a, b) => a.length > b.length ? a : b),
                    width - xOffset * 2,
                    entry.size * 1.1,
                    entry.bold) * entry.size :
                    entry.size

    const toNextPage = trueSize > nextPositionOnPage - yOffset

    const page = (toNextPage ? pdfDoc.addPage() : pdfDoc.getPages().at(-1)) as PDFPage

    const position = toNextPage ? height - entry.size - yOffset : height - entry.size - y

    const newY = toNextPage ? yOffset + entry.size : y + entry.size

    const extraOffsets = []

    if (entry.type === "pie") {
        await getPieAndAddToPDFDoc(pdfDoc, page, position, entry);
    }

    if (entry.type === "text" || entry.type === "columns" || entry.type === "header") {

        if (entry.backgroundColor) {
            extraOffsets.push(entry.size * .3)
            page.drawRectangle({
                x: 0,
                y: position - entry.size * .6,
                width: width,
                height: entry.size * 1.8,
                color: entry.backgroundColor
            })
        }

        const options: PDFPageDrawTextOptions = {
            x: entry.type === "header" ? (xOffset * 1.6) + entry.size : xOffset,
            y: position,
            size: entry.size,
            font: entry.bold ? fontBold : fontRegular,
            color: entry.backgroundColor ? {type: ColorTypes.RGB, red: 1, green: 1, blue: 1} : undefined,
            lineHeight: entry.size * 1.1,
            maxWidth: width - xOffset * 2,
        }


        if (entry.type === "text" || entry.type === "header") {
            const lines = calcLines(entry.text, width - xOffset * 2, entry.size * 1.1, entry.bold)
            extraOffsets.push(entry.size * (lines - 1))

            page.drawText(entry.text, {
                ...options,
                color: entry.text === PDFFactoryNoAnswerText ? {
                    type: ColorTypes.RGB,
                    red: 0.5,
                    green: 0.5,
                    blue: 0.5
                } : options.color,
            })
        }

        if (entry.type === "header") {
            const pngImageBytes = await fetch(entry.image).then((res) => res.arrayBuffer())
            const pngImage = await pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.5)

            const aspectRatio = 1

            page.drawImage(pngImage, {
                x: xOffset,
                y: position - ((entry.size) / 2),
                height: entry.size * 1.6,
                width: entry.size * 1.6
            })
        }

        if (entry.type === "columns") {
            const totalWidth = width - xOffset * 2
            const columnWidth = totalWidth / 4;

            const largestText: string = [entry.col1, entry.col2, entry.col3 ?? "", entry.col4 ?? ""]
                .reduce((a, b) => a.length > b.length ? a : b)
            const lines = calcLines(largestText, columnWidth * (entry.hasOwnProperty("col3") ? 1 : 3), entry.size * 1.1, entry.bold)
            if (lines > 1)
                extraOffsets.push((entry.size * 1.1) * (lines - 1))

            page.drawText(entry.col1, {
                ...options,
                color: entry.col1 === PDFFactoryNoAnswerText ? {
                    type: ColorTypes.RGB,
                    red: 0.5,
                    green: 0.5,
                    blue: 0.5
                } : options.color,
                x: xOffset,
                maxWidth: columnWidth,
                font: (entry.bold ?? true) ? fontBold : fontRegular
            })
            page.drawText(entry.col2, {
                ...options,
                color: entry.col2 === PDFFactoryNoAnswerText ? {
                    type: ColorTypes.RGB,
                    red: 0.5,
                    green: 0.5,
                    blue: 0.5
                } : options.color,
                x: xOffset + columnWidth,
                maxWidth: columnWidth * (entry.hasOwnProperty("col3") ? 1 : 3)
            })
            if (entry.hasOwnProperty("col3") && entry.hasOwnProperty("col4")) {
                page.drawText(entry.col3 ?? "", {
                    ...options,
                    color: entry.col3 === PDFFactoryNoAnswerText ? {
                        type: ColorTypes.RGB,
                        red: 0.5,
                        green: 0.5,
                        blue: 0.5
                    } : options.color,
                    x: xOffset + columnWidth * 2,
                    maxWidth: columnWidth,
                    font: (entry.bold ?? true) ? fontBold : fontRegular
                })
                page.drawText(entry.col4 ?? "", {
                    ...options,
                    color: entry.col4 === PDFFactoryNoAnswerText ? {
                        type: ColorTypes.RGB,
                        red: 0.5,
                        green: 0.5,
                        blue: 0.5
                    } : options.color,
                    x: xOffset + columnWidth * 3,
                    maxWidth: columnWidth
                })
            }
        }

    }

    if (entry.type === "margin") {
        page.drawText("", {
            x: xOffset,
            y: position,
            size: entry.size
        })
    }

    if (entry.type === "image") {
        const pngImageBytes = await fetch(entry.image).then((res) => res.arrayBuffer())
        const pngImage = await pdfDoc.embedPng(pngImageBytes)
        const pngDims = pngImage.scale(0.5)

        const aspectRatio = pngDims.width / pngDims.height

        page.drawImage(pngImage, {
            x: xOffset,
            y: position,
            height: entry.size,
            width: entry.size * aspectRatio
        })
    }

    if (entry.type === "range") {
        const totalWidth = width - xOffset * 2
        const columnWidth = totalWidth / 4;

        page.drawText(entry.text, {
            x: xOffset,
            y: position,
            size: entry.size,
            maxWidth: columnWidth * 2,
            font: fontBold
        })

        // Draw the background bar rounded corner
        page.drawCircle({
            x: xOffset + columnWidth * 4,
            y: position + entry.size / 2,
            size: entry.size / 2,
            color: {
                type: ColorTypes.RGB,
                red: 0.886,
                green: 0.910,
                blue: 0.941,
            } // blue color
        });

        // Draw the background
        page.drawRectangle({
            x: xOffset + columnWidth * 2 + (entry.size / 2),
            y: position,
            width: columnWidth * 2 - (entry.size / 2),
            height: entry.size,
            color: {
                type: ColorTypes.RGB,
                red: 0.886,
                green: 0.910,
                blue: 0.941
            } // light gray/blue color
        });

        // Draw the background bar (rounded rectangle)
        page.drawRectangle({
            x: xOffset + columnWidth * 2 + (entry.size / 2),
            y: position,
            width: (columnWidth * 2) * (entry.range / 100) - (entry.size / 2),
            height: entry.size,
            color: {
                type: ColorTypes.RGB,
                red: 0.682,
                green: 0.780,
                blue: 0.792
            } // light gray/blue color
        });

        // Draw the background bar rounded corner
        page.drawCircle({
            x: xOffset + columnWidth * 2 + (entry.size / 2),
            y: position + entry.size / 2,
            size: entry.size / 2,
            color: entry.range < 2 ? {
                type: ColorTypes.RGB,
                red: 0.886,
                green: 0.910,
                blue: 0.941,
            } : {
                type: ColorTypes.RGB,
                red: 0.682,
                green: 0.780,
                blue: 0.792
            } // blue color
        });

        // Draw the slider circle
        page.drawCircle({
            x: xOffset + columnWidth * 2 + (columnWidth * 2) * (entry.range / 100),
            y: position + entry.size / 2,
            size: entry.size / 1.5,
            color: {
                type: ColorTypes.RGB,
                red: 0.235,
                green: 0.416,
                blue: 0.624
            } // blue color
        });

    }

    const totalNewY = newY + extraOffsets.reduce((a, b) => a + b, 0)

    await addEntriesToDoc(pdfDoc, entry.type === "pie" ? toNextPage ? yOffset : y : totalNewY, entries.slice(1))
}

const getPieAndAddToPDFDoc = async (pdfDoc: PDFDocument, page: PDFPage, position: number, entry: {
    type: "pie",
    size: number
}) => {
    return new Promise<void>((resolve, reject) => {
        try {
            // Get the SVG element from the DOM
            const svgElement = document.getElementsByClassName('recharts-surface')[0] as HTMLElement;

            // Serialize the SVG element to a string
            const svgString = new XMLSerializer().serializeToString(svgElement);

            // Convert the string to a Blob
            const svgBlob = new Blob([svgString], {type: 'image/svg+xml;charset=utf-8'});

            const svgUrl = window.URL.createObjectURL(svgBlob);
            const img = document.createElement('img');

            img.onload = async (e) => {
                const canvas = document.getElementById('canvas') as HTMLCanvasElement;
                canvas.width = 600; // Set the canvas width
                canvas.height = 600; // Set the canvas height
                const ctx = canvas.getContext('2d');
                ctx!.clearRect(0, 0, canvas.width, canvas.height);
                ctx!.drawImage(img, 0, 0);

                // Release the object URL to free up memory
                window.URL.revokeObjectURL(svgUrl);

                // Convert the canvas to a PNG Blob
                canvas.toBlob(
                    async (blob) => {
                        try {
                            const pngImageBytes = await blob!.arrayBuffer();
                            const pngImage = await pdfDoc.embedPng(pngImageBytes);
                            const pngDims = pngImage.scale(0.5);

                            const aspectRatio = pngDims.width / pngDims.height;

                            // Draw the image on the PDF page
                            page.drawImage(pngImage, {
                                x: xOffset + 200,
                                y: position,
                                height: entry.size,
                                width: entry.size * aspectRatio,
                            });

                            // Resolve the promise once the drawing is done
                            resolve();
                        } catch (err) {
                            resolve();
                        }
                    },
                    'image/png',
                    1
                );
            };

            // Set the image source after the onload listener is added
            img.src = svgUrl;
        } catch (err) {
            resolve();
        }
    });
};

const PDFFactory = {

    generate: async (entries: PDFFactoryEntryType[]): Promise<PDFDocument> => {

        const pdfDoc = await PDFDocument.create()

        await addEntriesToDoc(pdfDoc, yOffset, entries)

        return pdfDoc;
    }

}

export {PDFFactory, PDFFactoryMargins, PDFFactoryFontSizes, PDFFactoryColors, PDFFactoryNoAnswerText};

import FormStorageType from "../../types/form/FormStorageType";

const LocalStorage: FormStorageType = {
    set: (key, value) => {
        value === "" ?
            localStorage.removeItem(key) :
            localStorage.setItem(key, value);
    },
    get: (key) => {
        return localStorage.getItem(key);
    },
    getJson: () => {
        return {};
    },
    getAll: () => {
        const keys = Object.keys(localStorage);

        const values: Record<string, string | null> = {}

        let i = keys.length;

        while ( i-- ) {
            values[keys[i]] = localStorage.getItem(keys[i]);
        }

        return values
    }
}

export {LocalStorage};

import Header from "../components/layout/Header";
import {Outlet} from "react-router-dom";

export default function Mentor() {

    return <>
        <div className="flex flex-col h-screen w-full">
            <Header/>
            <main>

                <div className="bg-lobcv-yellow-400 py-5">
                    <div className="container lg:max-w-[960px] mx-auto px-6">
                        <div className="flex">
                            <div className="flex justify-center align-middle flex-col w-6/12 ">
                                <h1 className="w-full lg:w-10/12 text-white text-3xl lg:text-4xl font-bold flex-grow-0">
                                    Begeleiders
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-12">
                    <div className="container lg:max-w-[960px] mx-auto px-6">
                        <div className="flex gap-10">
                            <div className="flex justify-center align-middle flex-col">
                                <h2 className="text-2xl lg:text-3xl font-bold mb-4">
                                    Het LOB-cv, een goede start in het hoger onderwijs</h2>

                                <p className="mb-3">
                                    <strong>Voor begeleiders van leerlingen/studenten:</strong>
                                </p>

                                <p className="mb-3">
                                    Uw leerlingen of studenten gaan binnenkort (door)studeren. Ze hebben hun studiekeuze al gemaakt of ze zijn nog bezig zich te oriënteren op een studie die bij hen past. Dit LOB-cv is een handig middel voor aankomende studenten om zich voor te bereiden op de eventuele studiekeuzecheck en de start van de studie bij hun vervolgopleiding. Door aan het LOB-cv te werken, krijgen ze een goed beeld van zichzelf en staan alle vaardigheden, verwachtingen, motivatie en andere zaken die bij hem/haar passen of die hij/zij belangrijk vindt op een rijtje.
                                </p>

                                <p className="mb-3">
                                    Om een goed beeld te krijgen wat het LOB-cv inhoudt, adviseren wij u om zelf ook een keer het LOB-cv in te vullen. Dan weet u precies welke vragen uw leerlingen/studenten gaan beantwoorden.
                                </p>

                                <p className="mb-3">
                                    Let op: Leerlingen/studenten zullen niet in een keer het gehele CV invullen. Wanneer zij een deel hebben ingevuld en hier later weer aan gaan werken, dan moeten zij wel op hetzelfde device (bijv. laptop) werken, anders zijn hun eerder ingevulde gegevens niet zichtbaar. De gegevens worden namelijk niet online opgeslagen maar blijven middels het plaatsen van een ‘cookie’ op hetzelfde device zichtbaar. Uiteraard kan tussentijds een pdf worden gemaakt, maar een pdf is statisch en niet aanpasbaar.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Outlet/>
        </div>
    </>
}

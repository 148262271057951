import React, {useState} from "react";
import HorizontalInput from "./layout/HorizontalInput";
import RadioField from "./common/RadioField";
import CheckboxField from "./common/CheckboxField";
import ChapterTitle from "../chapters/ChapterTitle";
import SchoolData from "../../assets/schools.json";
import {LocalStorage} from "../../services/form/LocalStorage";

type SelectInputPropsType = {
    label: string,
    name: string
}

type SelectSchoolInputState = {
    school: string,
    program: string,
    secondSchool: string,
    secondProgram: string
}

const SelectSchoolInputState = {
    default: (): SelectSchoolInputState => ({
        school: LocalStorage.get('lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_school') ?? '',
        program: LocalStorage.get('lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_program') ?? '',
        secondSchool: LocalStorage.get('lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondschool') ?? '',
        secondProgram:  LocalStorage.get('lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondprogram') ?? '',
    }),
    updaters: {
        setSchool: (school: string, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, school);
            return {...state, school: school};
        },
        setProgram: (program: string, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, program);
            return {...state, program: program};
        },
        setSecondSchool: (secondSchool: string, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, secondSchool);
            return {...state, secondSchool: secondSchool};
        },
        setSecondProgram: (secondProgram: string, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, secondProgram);
            return {...state, secondProgram: secondProgram};
        },
    }
}

export default function SelectSchoolInput(props: SelectInputPropsType) {

    const [state, setState] = useState<SelectSchoolInputState>(SelectSchoolInputState.default());

    const levels = ['vmbo', 'havo', 'vwo']

    return <>
        <ChapterTitle>{props.label}</ChapterTitle>

        <HorizontalInput label="School" name={props.name + "_school"}>
            <>
                <div className="grid">
                    <input type="text" id={props.name + "_school"} value={state.school}
                           onChange={(e) => {
                               setState(SelectSchoolInputState.updaters.setSchool(e.currentTarget.value, props.name + "_school"))
                           }}
                           className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent w-full"/>
                </div>
            </>
        </HorizontalInput>

        <HorizontalInput label="Opleiding" name={props.name + "_program"}>
            <>
                <div className="grid">
                    <input type="text" id={props.name + "_program"} value={state.program}
                           onChange={(e) => {
                               setState(SelectSchoolInputState.updaters.setProgram(e.currentTarget.value, props.name + "_program"))
                           }}
                           className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent"/>
                </div>
            </>
        </HorizontalInput>

        <ChapterTitle>Voor welke opleiding heb je gekozen of overweeg je? Studiekeuze (2)</ChapterTitle>

        <HorizontalInput label="School" name={props.name + "_secondschool"}>
            <>
                <div className="grid">
                    <input type="text" id={props.name + "_secondschool"} value={state.secondSchool}
                           onChange={(e) => {
                               setState(SelectSchoolInputState.updaters.setSecondSchool(e.currentTarget.value, props.name + "_secondschool"))
                           }}
                           className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent w-full"/>
                </div>
            </>
        </HorizontalInput>

        <HorizontalInput label="Opleiding" name={props.name + "_secondprogram"}>
            <>
                <div className="grid">
                    <input type="text" id={props.name + "_secondprogram"} value={state.secondProgram}
                           onChange={(e) => {
                               setState(SelectSchoolInputState.updaters.setSecondProgram(e.currentTarget.value, props.name + "_secondprogram"))
                           }}
                           className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent"/>
                </div>
            </>
        </HorizontalInput>
    </>
}

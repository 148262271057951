import {ChapterContentPartType} from "../../types/form/ChapterContentPartType";


type ChapterContentState = {
    loading: boolean,
    chapterContent: ChapterContentPartType[]
}

const ChapterContentStateRepository = {
    default: (): ChapterContentState => ({ loading: true, chapterContent: [] }),
    updates: {
        setChapterContent: (chapterContent: ChapterContentState["chapterContent"]) =>
            (state: ChapterContentState): ChapterContentState => ({ ...state, chapterContent }),
        setLoading: (loading: ChapterContentState["loading"]) => (state: ChapterContentState): ChapterContentState => ({ ...state, loading })
    }
}

export { ChapterContentStateRepository }
export type { ChapterContentState }

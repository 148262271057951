import {ReactElement} from "react";

export default function VerticalInput(props: {label: string, children: ReactElement, equalHalves?: boolean, name?: string}){
    return <>
        <div className="flex flex-wrap mb-3">
            <label className={"text-lobcv-blue-600 text-lg w-full"} htmlFor={props.name}>{props.label}</label>
            <div className="w-full">
                {props.children}
            </div>
        </div>
    </>
}

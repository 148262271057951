import ChapterContent from "../../types/form/ChapterContent";

import chapterContentData from "../../assets/chapters.json";
import {ChapterContentFileType, ChapterContentPartType} from "../../types/form/ChapterContentPartType";

const chapterContent: ChapterContentFileType = chapterContentData as ChapterContentFileType;

const LocalChapterContent: ChapterContent = {

    getChapterContent: async (chapterId: string): Promise<ChapterContentPartType[]> => {

        if(!chapterContent.hasOwnProperty(chapterId))
            return []

        return chapterContent[chapterId];
    },

    getAllContent: async (): Promise<ChapterContentFileType> => {
        return chapterContent;
    }

}

export default LocalChapterContent

import {PDFFactoryColors, PDFFactoryFontSizes, PDFFactoryMargins, PDFFactoryNoAnswerText} from "../PDFFactory";
import {RGB} from "pdf-lib";
import {LocalStorage} from "../LocalStorage";
import PDFFactoryEntryType from "../../../types/pdf/PDFFactoryEntryType";
import Utils from "../../Utils";

function* RenderStep1and2(): Generator<PDFFactoryEntryType> {

    const values = {
        woonplaats: LocalStorage.get("lobcv_answers_personal_information_woonplaats") ?? PDFFactoryNoAnswerText,
        geboortedatum: (LocalStorage.get("lobcv_answers_personal_information_geboortedatum") ?? PDFFactoryNoAnswerText).split("-").reverse().join("/"),
        nationaliteit: LocalStorage.get("lobcv_answers_personal_information_nationaliteit") ?? PDFFactoryNoAnswerText,
        school: LocalStorage.get("lobcv_answers_personal_information_naam-van-je-school") ?? PDFFactoryNoAnswerText,
        niveau: LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is_level") ?? PDFFactoryNoAnswerText,
        profiel: LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is_profile") ?? PDFFactoryNoAnswerText,
        vakken: Utils.chunk(getCourses(LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is_level") ?? PDFFactoryNoAnswerText), 4),
        opleidingMbo: LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is[program][mbo]") ?? PDFFactoryNoAnswerText,
        opleidingMboHbo: LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is[other-courses-mbo][mbo]") ?? PDFFactoryNoAnswerText,
        otherLevelDescription: LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is[detail][anders]") ?? PDFFactoryNoAnswerText,
        vakkenMbo: LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is[other-courses][mbo]") ?? PDFFactoryNoAnswerText,
        andereVakken: LocalStorage.get(`lobcv_answers_personal_information_mijn-vooropleiding-is[other-courses][${LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is_level") ?? PDFFactoryNoAnswerText}]`) ?? PDFFactoryNoAnswerText,
        onderwerpPWS: LocalStorage.get(`lobcv_answers_personal_information_mijn-vooropleiding-is[subject-pws][${LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is_level") ?? PDFFactoryNoAnswerText}]`) ?? PDFFactoryNoAnswerText,
        vrijetijd: LocalStorage.get("lobcv_answers_additional_information_wat-doe-ik-graag-in-mijn-vrije-tijd") ?? PDFFactoryNoAnswerText,
        bijbaan: LocalStorage.get("lobcv_answers_additional_information_mijn-bijbaan") ?? PDFFactoryNoAnswerText,
        stageErvaring: LocalStorage.get("lobcv_answers_additional_information_mijn-stage-ervaring") ?? PDFFactoryNoAnswerText,
        vrijwilligerswerk: LocalStorage.get("lobcv_answers_additional_information_mijn-vrijwilligerswerk") ?? PDFFactoryNoAnswerText,
        overigeActiviteiten: LocalStorage.get("lobcv_answers_additional_information_overige-activiteiten") ?? PDFFactoryNoAnswerText,
        bijzonderhedenStudieverloop: LocalStorage.get("lobcv_answers_additional_information_bijzonderheden-die-van-invloed-kunnen-zijn-op-mijn-studieverloop-zoals-dyslexie-autisme-topsporter-mantelzorger-etc") ?? PDFFactoryNoAnswerText,
        ervaringenInvloedOpStudiekeuze: LocalStorage.get("lobcv_answers_additional_information_welke-van-bovenstaande-werk-ervaringen-speelt-een-rol-in-het-maken-van-mijn-studiekeuze") ?? PDFFactoryNoAnswerText,
    }

    if (Object.values(values).filter(x => x.length > 0).length <= 0) return

    yield {
        type: "header",
        text: "Persoonlijke informatie",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.purple as RGB,
        image: "//" + window.location.host + "/assets/images/personal-information-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}

    if (values.woonplaats || values.geboortedatum || values.nationaliteit || values.school) {
        yield {
            type: "columns",
            col1: "Woonplaats:",
            col2: values.woonplaats,
            col3: "Geboortedatum:",
            col4: values.geboortedatum,
            size: PDFFactoryFontSizes.normal
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "columns",
            col1: "Nationaliteit:",
            col2: values.nationaliteit,
            col3: "School:",
            col4: values.school,
            size: PDFFactoryFontSizes.normal
        }
    }

    if (values.niveau) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "columns",
            col1: "Niveau:",
            col2: values.niveau,
            size: PDFFactoryFontSizes.normal
        }
    }

    if (['havo', 'vwo'].includes(values.niveau) && values.profiel) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: 'columns',
            col1: `Profiel ${values.niveau}:`,
            col2: values.profiel,
            size: PDFFactoryFontSizes.normal
        }
    }

    if (['havo', 'vwo'].includes(values.niveau) && values.profiel && values.vakken.length > 0) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Vakken:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        for (const vakkenChunk of values.vakken) {
            yield {type: "margin", size: PDFFactoryMargins.smaller}
            yield {
                type: 'columns',
                col1: vakkenChunk[0] ?? PDFFactoryNoAnswerText,
                col2: vakkenChunk[1] ?? PDFFactoryNoAnswerText,
                col3: vakkenChunk[2] ?? PDFFactoryNoAnswerText,
                col4: vakkenChunk[3] ?? PDFFactoryNoAnswerText,
                bold: false,
                size: PDFFactoryFontSizes.normal
            }
        }

        if (values.andereVakken) {
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: "Andere vakken:",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: values.andereVakken,
                size: PDFFactoryFontSizes.normal,
                bold: false
            }
        }
    }

    if (['mbo'].includes(values.niveau)) {
        if (values.opleidingMbo) {
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: "Opleiding:",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: values.opleidingMbo,
                size: PDFFactoryFontSizes.normal,
                bold: false
            }
        }

        if (values.vakkenMbo) {
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: "Vakken:",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: values.vakkenMbo,
                size: PDFFactoryFontSizes.normal,
                bold: false
            }
        }

        if (values.opleidingMboHbo) {
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: "Keuzedeel voorbereiding HBO:",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: values.opleidingMboHbo,
                size: PDFFactoryFontSizes.normal,
                bold: false
            }
        }
    }

    if (['anders'].includes(values.niveau)) {
        if (values.otherLevelDescription) {
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: "Toelichting:",
                size: PDFFactoryFontSizes.normal,
                bold: true
            }
            yield {type: "margin", size: PDFFactoryMargins.small}
            yield {
                type: "text",
                text: values.otherLevelDescription,
                size: PDFFactoryFontSizes.normal,
                bold: false
            }
        }
    }

    if (values.onderwerpPWS) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Onderwerp profielwerkstuk:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.onderwerpPWS,
            size: PDFFactoryFontSizes.normal,
            bold: false
        }
    }

    if (values.vrijetijd || values.bijbaan || values.stageErvaring || values.vrijwilligerswerk || values.overigeActiviteiten || values.bijzonderhedenStudieverloop || values.ervaringenInvloedOpStudiekeuze) {
        yield {type: "margin", size: PDFFactoryMargins.large}
        yield {
            type: "text",
            text: "Meer over jezelf",
            size: PDFFactoryFontSizes.larger,
            bold: true
        }
    }

    if (values.vrijetijd) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Wat doe ik graag in mijn vrije tijd:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.vrijetijd,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.bijbaan) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Mijn bijbaan:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.bijbaan,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.stageErvaring) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Mijn stage-ervaring:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.stageErvaring,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.vrijwilligerswerk) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Mijn vrijwilligerswerk:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.vrijwilligerswerk,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.overigeActiviteiten) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Overige activiteiten:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.overigeActiviteiten,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.bijzonderhedenStudieverloop) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Bijzonderheden die van invloed kunnen zijn op mijn studieverloop (zoals dyslexie, autisme, topsporter, mantelzorger etc.):",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.bijzonderhedenStudieverloop,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (values.ervaringenInvloedOpStudiekeuze) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Welke van bovenstaande (werk-)ervaringen speelt een rol in het maken van mijn studiekeuze:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.ervaringenInvloedOpStudiekeuze,
            size: PDFFactoryFontSizes.smaller
        }
    }

}

const getCourses = (level: string) => Object.keys(LocalStorage.getAll())
    .filter(x => x.includes(`lobcv_answers_personal_information_mijn-vooropleiding-is[courses][${level}]`))
    .filter(x => LocalStorage.getAll()[x] === "true")
    .map(x => {
        const _ = x.split('][')
        const __ = _.at(-1) ?? ''
        return __.replace(']', '')
    })

export default RenderStep1and2

import {FormEvent} from "react";
import {RxCross2} from "react-icons/rx";

type RadioButtonProps = {
    name: string,
    value: string,
    onChange: (e: FormEvent<HTMLInputElement>) => void,
    type?: string,
}

const TextInputField = (props: RadioButtonProps) => {
    return <>
        <input
            name={props.name}
            id={props.name}
            onChange={props.onChange}
            type={props.type}
            value={props.value}
            max={ props.type === 'date' ? new Date().toISOString().split('T')[0] : undefined }
            className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent w-full"/>

    </>
}

export default TextInputField;

import HorizontalInput from "./layout/HorizontalInput";
import React, {useState} from "react";
import InputStateType from "../../types/form/InputStateType";
import {LocalStorage} from "../../services/form/LocalStorage";

type SelectInputState = InputStateType

const SelectInputState = {
    default: (name: string): SelectInputState => ({value: LocalStorage.get(name)}),
    updaters: {
        setValue: (name: string, value: string) => (state: SelectInputState): SelectInputState => {
            LocalStorage.set(name, value);
            return {...state, value: value};
        }
    }
}

type SelectInputPropsType = {
    label: string,
    name: string,
    options: string[]
}

export default function SelectInput(props: SelectInputPropsType) {

    const [state, setState] = useState<SelectInputState>(SelectInputState.default(props.name));

    return <>
        <HorizontalInput label={props.label} name={props.name}>
            <>
                <div className="flex w-full h-full">
                    <select className="w-full bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent"
                            id={props.name}
                            value={state.value ?? ''}
                            onChange={(e) => setState(SelectInputState.updaters.setValue(props.name, e.target.value))}
                            name={props.name}>
                        <option value="">Maak uw keuze</option>
                        {
                            props.options.map((option, index) => {
                                return <option key={index} className="" value={option}>
                                    {option}
                                </option>
                            })
                        }
                    </select>
                </div>
            </>
        </HorizontalInput>
    </>
}

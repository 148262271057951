import {ReactElement, useEffect, useState} from "react";
import {PiFilePdfDuotone} from "react-icons/pi";
import {MdDownloadForOffline} from "react-icons/md";
import RoundButton from "../RoundButton";
import {PDFExport} from "../../services/form/PDFExport";
import {FiLoader} from "react-icons/fi";
import {CgSpinner, CgSpinnerAlt, CgSpinnerTwo} from "react-icons/cg";
import {ImSpinner8} from "react-icons/im";

type ChapterLayoutState = {
    pdfLoading: boolean
}

const ChapterLayoutState = {
    default: () => ({pdfLoading: false}),
    updaters: {
        setLoading: (loading: boolean) => (state: ChapterLayoutState) => ({...state, pdfLoading: loading})
    }
}

export default function ChapterLayout(props: {
    title: string,
    step: number,
    color: "purple" | "red" | "yellow" | "aqua" | "green" | "gray",
    image: ReactElement,
    children: ReactElement | ReactElement[],
    paging: ReactElement
}) {

    const [state, setState] = useState<ChapterLayoutState>(ChapterLayoutState.default())

    const downloadPdf = () => {
        setState(ChapterLayoutState.updaters.setLoading(true))
        window.dispatchEvent(new Event('lobcv:export:pdf:export:start'))
        PDFExport.serve().then(pdf => {
            setState(ChapterLayoutState.updaters.setLoading(false))
            window.dispatchEvent(new Event('lobcv:export:pdf:export:complete'))
        })
    }

    useEffect(() => {
        window.addEventListener('lobcv:export:pdf:init', downloadPdf)
    }, []);

    return <>
        <main className={
            (
                props.color === "purple" ? "purple bg-lobcv-purple-600" : '' +
                props.color === "aqua" ? "aqua bg-lobcv-aqua-400" : '' +
                props.color === "gray" ? "gray bg-lobcv-gray-300" : '' +
                props.color === "green" ? "green bg-lobcv-green-400" : '' +
                props.color === "red" ? "red bg-lobcv-red-500" : '' +
                props.color === "yellow" ? "yellow bg-lobcv-yellow-300" : ''
            )
        }>
            <div className="container px-6 mx-auto w-full py-6 lg:py-12 h-[calc(100vh-4rem)] lg:h-[calc(100vh-5rem)]">

                <title
                    className="w-full h-[calc(5rem)] lg:h-[calc(5.5rem)] flex border-b border-b-white">

                    <div className="flex flex-col xl:flex-row flex-grow-0 w-[calc(100%-3.5rem)] h-auto mt-auto pb-2 xl:pb-4">
                        <span
                            className="w-full xl:w-[150px] xl:pe-3 text-white text-xl xl:text-4xl font-bold flex-grow-0">Stap {props.step}</span>
                        <h1 className="w-full xl:w-[calc(100%-150px)] xl:ps-3 text-white text-2xl sm:text-3xl xl:text-4xl font-bold flex-grow-0 truncate">
                            {props.title}
                        </h1>
                    </div>

                    <RoundButton
                        disabled={state.pdfLoading}
                        onClick={downloadPdf}
                        className={"sticky top-0 ms-auto my-auto " + (state.pdfLoading ? " bg-white/60 hover:bg-white/60" : "")}>
                        {
                            state.pdfLoading ?
                                <CgSpinnerAlt
                                    className="text-[4.2rem] absolute text-white  animate-spin"/> :
                                <></>
                        }
                        <PiFilePdfDuotone
                            className={"text-4xl text-slate-700 " + (state.pdfLoading ? " opacity-60" : "")}/>
                    </RoundButton>
                </title>

                <div className="h-[calc(100%-5rem)] lg:h-[calc(100%-4rem)] pt-4">
                    <div className="flex h-full">
                        <div className="hidden xl:block">
                            <div className="w-[150px] pe-3">
                                {props.image}
                            </div>
                        </div>
                        <div className="w-full xl:ps-3">
                            <div className="rounded-2xl h-full overflow-hidden">
                                <div
                                    className="p-6 w-full h-full bg-slate-200 pb-32 lg:pb-40 relative overflow-y-scroll">
                                    <div className="flex flex-col lg:flex-row">
                                        <div className="flex-grow">
                                            {props.children}
                                        </div>
                                    </div>
                                </div>
                                {props.paging}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    </>
}

import React, {ReactElement, useEffect, useState} from "react";
import {Link, NavLink, NavLinkProps, NavLinkRenderProps, Route, useLocation} from "react-router-dom";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import RoundButton from "../RoundButton";
import WrapperElementPropsType from "../../types/component/WrapperElementPropsType";
import {PDFExport} from "../../services/form/PDFExport";
import {CgSpinnerAlt} from "react-icons/cg";
import {PiFilePdfDuotone} from "react-icons/pi";
import {HiDocumentDownload} from "react-icons/hi";
import {FaFileDownload} from "react-icons/fa";

type PagingItemProps = WrapperElementPropsType & {
    to: string,
    active?: boolean,
    disabled?: boolean,
    mobile?: boolean,
    onClick?: () => void
}

const PagingItem = ({
                        to,
                        active,
                        disabled,
                        mobile,
                        children,
                        className = "",
                        onClick = () => {
                        }
                    }: PagingItemProps) => {

    const location = useLocation();
    const {hash, pathname, search} = location;

    const disabledClasses = "pointer-events-none opacity-50"

    const baseClasses = "mx-2 text-xl lg:text-3xl font-bold "

    const combinedClasses =
        `${baseClasses} ${className} ${mobile ? " flex " : " hidden xl:flex "} ${disabled ? disabledClasses : ""}`;

    return <>
        {
            to ?
                <RoundButton
                    active={active || pathname === to}
                    to={to}
                    className={combinedClasses}
                    sizeClasses={"h-12 lg:h-16 " + (typeof children === 'object' ? "w-auto px-6" : "w-16")}
                >
                    {children}
                </RoundButton> :
                <RoundButton
                    active={active || pathname === to}
                    onClick={onClick}
                    className={combinedClasses}
                    sizeClasses={"h-12 lg:h-16 " + (typeof children === 'object' ? "w-auto px-6" : "w-16")}
                >
                    {children}
                </RoundButton>
        }
    </>
}

type PagingState = {
    pdfLoading: boolean
}

const PagingStateRepository = {
    default: () => ({pdfLoading: false}),
    updaters: {
        setLoading: (loading: boolean) => (state: PagingState) => ({...state, pdfLoading: loading})
    }
}

export default function Paging(props: { nextTo?: string, nextStep: string, prevTo?: string, end?: boolean }) {

    const [state, setState] = useState<PagingState>(PagingStateRepository.default())

    useEffect(() => {
        window.addEventListener('lobcv:export:pdf:export:complete', () => {
            setState(PagingStateRepository.updaters.setLoading(false))
        })
    }, []);

    useEffect(() => {
        window.addEventListener('lobcv:export:pdf:export:start', () => {
            setState(PagingStateRepository.updaters.setLoading(true))
        })
    }, []);

    return <>
        <div
            className="h-32 lg:h-40 w-full flex sticky bottom-0 left-0 px-6 bg-gradient-to-t from-slate-200 from-65% to-100%">
            <div className="flex w-full items-center justify-center mt-auto pb-6">

                <PagingItem
                    mobile
                    disabled={!props.prevTo}
                    className="me-auto ms-0"
                    to={props.prevTo ?? ''}>
                    <span>Terug</span>
                </PagingItem>

                <PagingItem to="/persoonlijke-informatie">1</PagingItem>
                <PagingItem to="/aanvullende-informatie">2</PagingItem>
                <PagingItem to="/vaardigheden-en-kwaliteiten">3</PagingItem>
                <PagingItem to="/studiekeuzeproces-en-orientatie">4</PagingItem>
                <PagingItem to="/motivatie-en-drijfveren">5</PagingItem>
                <PagingItem to="/verwachtingen">6</PagingItem>
                <PagingItem to="/bedankt">7</PagingItem>

                {
                    props.end ?
                        <>
                            <PagingItem
                                mobile
                                active
                                to=""
                                disabled={state.pdfLoading}
                                onClick={() => {
                                    setState(PagingStateRepository.updaters.setLoading(true))
                                    window.dispatchEvent(new Event('lobcv:export:pdf:init'));
                                }}
                                className="ms-auto me-0 flex">
                                <span className="flex items-center">Download CV</span>
                            </PagingItem>
                        </>
                        :
                        <PagingItem
                            mobile
                            active
                            disabled={!props.nextTo}
                            className="ms-auto me-0 flex"
                            to={props.nextTo ?? ''}>
                            <span className="flex items-center">{props.nextStep}</span>
                        </PagingItem>
                }

            </div>
        </div>
    </>
}

import {PDFFactoryColors, PDFFactoryFontSizes, PDFFactoryMargins, PDFFactoryNoAnswerText} from "../PDFFactory";
import {RGB} from "pdf-lib";
import {LocalStorage} from "../LocalStorage";
import PDFFactoryEntryType from "../../../types/pdf/PDFFactoryEntryType";
import {OrderedMap} from "immutable";
import MultiSelectEntryType from "../../../types/form/MultiSelectEntryType";
import Utils from "../../Utils";
import SchoolData from "../../../assets/schools.json";

function* RenderStep5(): Generator<PDFFactoryEntryType> {

    const values = {
        school1: LocalStorage.get("lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_school") ?? PDFFactoryNoAnswerText,
        opleiding1: LocalStorage.get("lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_program") ?? PDFFactoryNoAnswerText,
        school2: LocalStorage.get("lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondschool") ?? PDFFactoryNoAnswerText,
        opleiding2: LocalStorage.get("lobcv_answers_motivation_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondprogram") ?? PDFFactoryNoAnswerText,
        werkervaring: LocalStorage.get("lobcv_answers_motivation_het-belangrijkste-bij-het-maken-van-een-studiekeuze-is-voor-mij") ?? PDFFactoryNoAnswerText,
    }

    if (Object.values(values).filter(x => x.length > 0).length <= 0) return

    yield {
        type: "header",
        text: "Motivatie en drijfveren",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.red as RGB,
        image: "//" + window.location.host + "/assets/images/motivation-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}


    if (values.school1 && values.opleiding1) {
        yield {
            type: "text",
            text: "Voor welke opleiding heb je gekozen of overweeg je? Studiekeuze (1)",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: 'columns',
            col1: "School:",
            col2: values.school1,
            size: PDFFactoryFontSizes.normal
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: 'columns',
            col1: "Opleiding:",
            col2: values.opleiding1,
            size: PDFFactoryFontSizes.normal
        }
    }

    if (values.school2 && values.opleiding2) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Voor welke opleiding heb je gekozen of overweeg je? Studiekeuze (2)",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: 'columns',
            col1: "School:",
            col2: values.school2,
            size: PDFFactoryFontSizes.normal
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: 'columns',
            col1: "Opleiding:",
            col2: values.opleiding2,
            size: PDFFactoryFontSizes.normal
        }
    }

    if (values.werkervaring) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Welke van bovenstaande (werk-)ervaringen speelt een rol in het maken van mijn studiekeuze:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.werkervaring,
            size: PDFFactoryFontSizes.smaller
        }
    }

}

export default RenderStep5

import HorizontalInput from "./layout/HorizontalInput";
import {useState} from "react";
import InputStateType from "../../types/form/InputStateType";
import {LocalStorage} from "../../services/form/LocalStorage";
import VerticalInput from "./layout/VerticalInput";
import TextInputField from "./common/TextInputField";
import TextareaInputField from "./common/TextareaInputField";
import CheckboxField from "./common/CheckboxField";

type CheckboxInputState = {
    checked: boolean;
}

type CheckboxInputProps = {
    label: string;
    name: string;
}

const CheckboxInputState = {
    default: (name: string): CheckboxInputState => ({checked: LocalStorage.get(name) === "true"}),
    updaters: {
        setValue: (name: string, checked: boolean) => (state: CheckboxInputState): CheckboxInputState => {
            LocalStorage.set(name, checked.toString());
            return {...state, checked: checked};
        }
    }
}

export default function CheckboxInput(props: CheckboxInputProps) {

    const [state, setState] = useState<CheckboxInputState>(CheckboxInputState.default(props.name));

    return <>
        <CheckboxField checked={state.checked} label={props.label} name={props.name} value={"1"} onChange={(e) => {
            setState(CheckboxInputState.updaters.setValue(props.name, e.currentTarget.checked))
        }}/>
    </>
}

import {ReactElement} from "react";

export default function HorizontalInput(props: {label: string, children: ReactElement, equalHalves?: boolean, name?: string, labelTop?: boolean}){

    return <>
        <div className="flex flex-wrap mb-3">
            <label className={"flex flex-col text-lobcv-blue-600 text-lg w-full mb-3 pr-5 "
                + (props.equalHalves ? 'lg:w-1/2' : 'lg:w-1/3')
                + (props.labelTop ? '' : ' justify-center')
            }
                   htmlFor={props.name}>{props.label}</label>
            <div className={ "w-full flex flex-col justify-center " + (props.equalHalves ? 'lg:w-1/2' : 'lg:w-2/3') }>
                {props.children}
            </div>
        </div>
    </>
}

import Header from "../components/layout/Header";
import {Outlet} from "react-router-dom";

export default function About() {

    return <>
        <div className="flex flex-col h-screen w-full">
            <Header/>
            <main>

                <div className="bg-lobcv-green-400 py-5">
                    <div className="container lg:max-w-[960px] mx-auto px-6">
                        <div className="flex">
                            <div className="flex justify-center align-middle flex-col w-6/12 ">
                                <h1 className="w-full lg:w-10/12 text-white text-3xl lg:text-4xl font-bold flex-grow-0">
                                    Over het Lob-cv
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-12">
                    <div className="container mx-auto px-6 lg:max-w-[960px]">
                        <div className="flex gap-10">
                            <div className="flex justify-center align-middle flex-col">
                                <h2 className="text-2xl lg:text-3xl font-bold mb-4">
                                    Het LOB-cv, een goede voorbereiding op je start in het hoger onderwijs</h2>


                                <p className="mb-3">
                                    Binnenkort ga je studeren. Je hebt je studiekeuze al gemaakt of je bent nog bezig je
                                    te oriënteren op een studie die bij je past. Dit LOB-cv is een handig middel dat je
                                    kan helpen om een opleiding te kiezen, of dat je kunt gebruiken om je voor te
                                    bereiden op een eventuele studiekeuzecheck en de start van je studie in het hoger
                                    onderwijs. Door te werken aan je LOB-cv, krijg je een goed beeld van jezelf en heb
                                    je alles wat belangrijk is voor het maken van een studiekeuze en een goede
                                    voorbereiding op een rijtje. Het is belangrijk dat je goed nadenkt over wat voor jou
                                    belangrijk is bij de oriëntatie en de overstap naar het hoger onderwijs en dat je
                                    jouw extra activiteiten tijdens je schoolcarrière goed documenteert.
                                </p>

                                <p className="mb-3">
                                    Van jou als aankomend student wordt verwacht dat je jezelf goed kent. Waar ben je
                                    goed in (oftewel competent) en wat zijn nog leerpunten voor je? Hoe heb je je
                                    georiënteerd bij je studiekeuze en wat is je motivatie om te kiezen voor die ene
                                    opleiding? Welke verwachtingen heb je van studeren in het hoger onderwijs? Welke
                                    extra activiteiten heb je ondernomen?
                                </p>

                                <p className="mb-3">
                                    In de start van je studie kan je het LOB-cv gebruiken tijdens de
                                    studieloopbaangeleiding. Met dit LOB-cv kun je je goed presenteren. Neem het CV dus
                                    mee als je op gesprek gaat bij je vervolgopleiding, dan kan je samen met de
                                    contactpersoon bepalen of de studie waarvoor je hebt gekozen ook echt bij je past!
                                </p>

                                <p><strong>Aan de slag met het LOB-cv</strong></p>

                                <p className="mb-3">
                                    Doorloop alle onderdelen en vul alles zo goed mogelijk in. Denk goed na over wat je
                                    in je LOB-cv wilt opnemen (of wat juist niet) en waarom. Vul het serieus in, anders
                                    doe je jezelf tekort. Maak het jezelf ook niet te makkelijk met korte zinnen of één
                                    woord. Let ook op je taalvaardigheid. En vul het eerlijk in, anders kan de
                                    contactpersoon niet goed met je meedenken of dit echt de best passende studie voor
                                    je is.
                                </p>

                                <p><strong>Disclaimer</strong></p>

                                <p className="mb-3">
                                    Het LOB-cv is een samenwerking van Hogeschool Rotterdam, Hogeschool Utrecht, Erasmus
                                    Universiteit, InHolland, De Haagse Hogeschool en Spirit4you. Het LOB-cv is in
                                    gezamenlijkheid ontwikkeld door de vier Rotterdamse Hogescholen en de vo-scholen.
                                    Het auteursrecht op het LOB-cv.nl berust bij het samenwerkingsverband voho010 “Samen
                                    werken aan een betere aansluiting”. Vermenigvuldiging in wat voor vorm dan ook is
                                    alleen toegestaan na voorafgaande toestemming.
                                </p>

                                <p className="mb-3">
                                    <strong>Meer informatie?</strong>
                                </p>

                                <p>
                                    Voor de regio Rotterdam <a
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    href="mailto:SamenwerkingVO-HO@hr.nl">SamenwerkingVO-HO@hr.nl</a>
                                </p>

                                <p>
                                    Voor de regio Haaglanden <a
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    href="mailto:info@spirit4you.nl">info@spirit4you.nl</a>
                                </p>

                                <p>
                                    Voor de regio Utrecht <a
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    href="mailto:desire.engel@hu.nl">desire.engel@hu.nl</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Outlet/>
        </div>
    </>
}

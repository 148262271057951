const Slugger = {
    slugify: (str: string): string => {
        return str
            .replace(/^\s+|\s+$/g, '') // trim leading/trailing white space
            .toLowerCase() // convert string to lowercase
            .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
            .replace(/\s+/g, '-') // replace spaces with hyphens
            .replace(/-+/g, '-') // remove consecutive hyphens
    }
}

export default Slugger

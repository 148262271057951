import Header from "../components/layout/Header";
import RoundButton from "../components/RoundButton";

export default function Home() {
    return <>
        <div className="flex flex-col h-screen w-full">
            <Header/>

            <div className="bg-lobcv-aqua-400 py-8 mb-8">

                <div className="container mx-auto px-6">

                    <div className="flex flex-wrap text-white">

                        <div className="lg:pe-4 w-full lg:w-1/2 my-auto">

                            <h1 className="text-2xl lg:text-3xl font-bold mb-4">Welkom bij het LOB-cv!</h1>

                            <p className="text-white text-lg hidden md:block">
                                Het kiezen van een profiel of een vervolgopleiding is een belangrijke gebeurtenis. Het
                                is dan fijn wanneer je meer inzicht hebt in je eigen kwaliteiten, interesses en
                                motieven. Met dit LOB-cv zet je alles op een rijtje om je voor te bereiden op je start
                                in het hoger onderwijs. Begin daarom snel. Is het klaar, dan kan je het LOB-cv gebruiken
                                bij je keuze voor een opleiding, een eventuele studiekeuzecheck en bij de start van je
                                vervolgopleiding.
                            </p>

                        </div>

                        <div className="lg:ps-4 w-full my-auto lg:w-1/2 flex-grow-0 mt-8 lg:mt-auto lg:h-auto">

                            <img className="max-w-full mb-12 mx-auto"
                                 src="/assets/images/home-illustration-big.png" alt="LOB-cv"/>

                            <div className="flex justify-between flex-wrap mx-auto max-w-[600px]">

                                <RoundButton
                                    to="/over-het-lob-cv"
                                    className="px-6 md:px-12 py-2 text-md md:text-lg lg:text-xl font-bold mt-3"
                                    sizeClasses="w-auto"
                                >
                                    Lees meer
                                </RoundButton>

                                <RoundButton
                                    to="/persoonlijke-informatie"
                                    sizeClasses="w-auto"
                                    className="px-6 md:px-12 py-2 text-md md:text-lg lg:text-xl font-bold mt-3"
                                    active
                                >
                                    Maak nu jouw CV
                                </RoundButton>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container mx-auto px-6 pb-12">

                <div className="flex flex-wrap flex-col-reverse xl:flex-row">

                    <div className="xl:pe-4 w-full xl:w-1/2 flex flex-col align-center">
                        <iframe className="w-full aspect-video rounded-md"
                                src="https://player.vimeo.com/video/986343175?h=2ccadf2da3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                title="Het LOB-cv"></iframe>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>


                    <div
                        className="flex flex-col justify-start xl:ps-4 w-full xl:w-1/2 mt-0 text-lobcv-aqua-600 mb-6 xl:mb-0">

                        <div className="mb-8 p-6 rounded-md bg-amber-50 text-amber-800 shadow-md">
                            <p><span className="font-semibold">LET OP:</span> wanneer je een deel van het CV hebt ingevuld en hier later weer aan gaat werken,
                                dan moet je dat wel op hetzelfde device (bijv. laptop) doen, anders zijn jouw eerder
                                ingevulde gegevens niet zichtbaar. De gegevens worden namelijk niet online opgeslagen.
                                Uiteraard kun je tussentijds een pdf maken, maar een pdf is statisch en niet
                                aanpasbaar.</p>
                        </div>

                        <h1 className="text-2xl lg:text-3xl font-bold mb-4">
                            Het LOB-cv, een goede
                            voorbereiding op je start in het
                            hoger onderwijs
                        </h1>

                        <p>
                            Binnenkort ga je studeren. Je hebt je studiekeuze al gemaakt of je bent nog bezig je te
                            oriënteren op een studie die bij je past. Dit LOB-cv is een handig middel dat je kan helpen
                            om een opleiding te kiezen, of dat je kunt gebruiken om je voor te bereiden op een eventuele
                            studiekeuzecheck en de start van je studie in het hoger onderwijs. Door te werken aan je
                            LOB-cv, krijg je een goed beeld van jezelf en heb je alles wat belangrijk is voor het maken
                            van een studiekeuze en een goede voorbereiding op een rijtje. Het is belangrijk dat je goed
                            nadenkt over wat voor jou belangrijk is bij de oriëntatie en de overstap naar het hoger
                            onderwijs en dat je jouw extra activiteiten tijdens je schoolcarrière goed documenteert.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
}

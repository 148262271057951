import {FormEvent} from "react";

type RadioButtonProps = {
    label: string,
    name: string,
    value: string,
    onChange: (e: FormEvent<HTMLInputElement>) => void,
    defaultChecked?: boolean
}

const RadioField = (props: RadioButtonProps) => {
    return <>
        <label className="inline-flex items-center me-4 cursor-pointer">
            <input type="radio"
                   onChange={props.onChange}
                   value={props.value}
                   name={props.name}
                   defaultChecked={props.defaultChecked ?? false}
                   className="hidden peer"/>
            <span
                className="h-6 w-6 bg-white border-lobcv-blue-600 border-2 rounded-full inline-flex items-center justify-center flex-shrink-0"/>
            <span className="h-4 w-4 rounded-full absolute ms-1  peer-hover:bg-lobcv-blue-300 peer-checked:!bg-lobcv-blue-600"/>
            <span className="ml-2 text-lobcv-blue-600 text-lg flex-shrink truncate">{props.label}</span>
        </label>
    </>
}

export default RadioField;

import {ReactElement} from "react";
import {NavLink} from "react-router-dom";
import WrapperElementPropsType from "../types/component/WrapperElementPropsType";

type RoundButtonProps = WrapperElementPropsType & {
    colorClasses?: string,
    sizeClasses?: string,
    active?: boolean,
    disabled?: boolean
} & (
    | { to: string; onClick?: never }
    | { onClick: () => void; to?: never }
    )

const RoundButton = ({
                         sizeClasses = "w-14 h-14",
                         className = "",
                         to,
                         onClick,
                         children,
                         active = false,
                         disabled = false,
                     }: RoundButtonProps) => {

    const baseClasses = "flex items-center justify-center rounded-full transition";
    const colorClasses = active ? "bg-lobcv-blue-600 text-white hover:bg-lobcv-blue-400" : " bg-white text-lobcv-blue-600 hover:bg-lobcv-blue-200";
    const combinedClasses = `${baseClasses} ${sizeClasses} ${className} ${colorClasses}`;

    return to ? (
        <NavLink to={to} className={combinedClasses}>
            {children}
        </NavLink>
    ) : (
        <button onClick={onClick} className={combinedClasses} disabled={disabled}>
            {children}
        </button>
    )
}

export default RoundButton;

import {PDFFactoryColors, PDFFactoryFontSizes, PDFFactoryMargins, PDFFactoryNoAnswerText} from "../PDFFactory";
import {RGB} from "pdf-lib";
import {LocalStorage} from "../LocalStorage";
import PDFFactoryEntryType from "../../../types/pdf/PDFFactoryEntryType";

function* RenderStep3(): Generator<PDFFactoryEntryType> {

    const values = {
        plannen: parseInt(LocalStorage.get("lobcv_answers_personal_information_plannen-en-zelfstandig-werken")?? '50') ?? PDFFactoryNoAnswerText,
        informatieZoeken: parseInt(LocalStorage.get("lobcv_answers_personal_information_informatie-zoeken-en-verwerken")?? '50') ?? PDFFactoryNoAnswerText,
        tekstenLezen: parseInt(LocalStorage.get("lobcv_answers_personal_information_lange-teksten-lezen-en-leren")?? '50') ?? PDFFactoryNoAnswerText,
        samenwerken: parseInt(LocalStorage.get("lobcv_answers_personal_information_samenwerken")?? '50') ?? PDFFactoryNoAnswerText,
        analyseren: parseInt(LocalStorage.get("lobcv_answers_personal_information_analyseren")?? '50') ?? PDFFactoryNoAnswerText,
        ict: parseInt(LocalStorage.get("lobcv_answers_personal_information_ict-gebruiken")?? '50') ?? PDFFactoryNoAnswerText,
        schriftelijkCommuniceren: parseInt(LocalStorage.get("lobcv_answers_personal_information_schriftelijk-communiceren-bijv-een-verslag-maken")?? '50') ?? PDFFactoryNoAnswerText,
        presenteren: parseInt(LocalStorage.get("lobcv_answers_personal_information_presenteren-uitleg-geven")?? '50') ?? PDFFactoryNoAnswerText,
        reflecteren: parseInt(LocalStorage.get("lobcv_answers_personal_information_reflecteren")?? '50') ?? PDFFactoryNoAnswerText,
        kernkwaliteitenSterk: LocalStorage.get("lobcv_answers_personal_information_kies-nu-minimaal-3-kernkwaliteiten-waar-je-sterk-in-bent-en-geef-van-daar-voorbeelden-van") ?? PDFFactoryNoAnswerText,
        kernkwaliteitenVerbeteren: LocalStorage.get("lobcv_answers_personal_information_kies-ook-3-kernkwaliteiten-waarin-je-jezelf-nog-kan-verbeteren") ?? PDFFactoryNoAnswerText,
        kernkwaliteitenOpleiding: LocalStorage.get("lobcv_answers_personal_information_welke-van-mijn-gekozen-kernkwaliteiten-denk-ik-nodig-te-hebben-bij-mijn-vervolgopleiding") ?? PDFFactoryNoAnswerText
    }

    yield {
        type: "header",
        text: "Vaardigheden en kwaliteiten",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.blue as RGB,
        image: "//" + window.location.host + "/assets/images/skills-and-competencies-icon.png",
    }

    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "columns",
        col1: "",
        col2: "",
        col3: "kan ik nog niet:",
        col4: "\t\t\t  doe ik perfect",
        bold: true,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Plannen en zelfstandig werken",
        range: values.plannen,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Informatie zoeken en verwerken",
        range: values.informatieZoeken,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "(Lange) teksten lezen en leren",
        range: values.tekstenLezen,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Samenwerken",
        range: values.samenwerken,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Analyseren",
        range: values.analyseren,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "ICT gebruiken",
        range: values.ict,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Schriftelijk communiceren",
        range: values.schriftelijkCommuniceren,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Presenteren / uitleg geven",
        range: values.presenteren,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Reflecteren",
        range: values.reflecteren,
        size: PDFFactoryFontSizes.normal
    }

    yield {type: "margin", size: PDFFactoryMargins.large}

    if(values.kernkwaliteitenSterk){
        yield {
            type: "text",
            text: "Kies nu minimaal 3 kernkwaliteiten waar je sterk in bent en geef van daar voorbeelden van:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.kernkwaliteitenSterk,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if(values.kernkwaliteitenVerbeteren){
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Kies ook 3 kernkwaliteiten waarin je jezelf nog kan verbeteren:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.kernkwaliteitenVerbeteren,
            size: PDFFactoryFontSizes.smaller
        }
    }

    if(values.kernkwaliteitenOpleiding){
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: "Welke van mijn gekozen kernkwaliteiten denk ik nodig te hebben bij mijn vervolgopleiding:",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: values.kernkwaliteitenOpleiding,
            size: PDFFactoryFontSizes.smaller
        }
    }

}

export default RenderStep3

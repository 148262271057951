import CheckboxField from "./common/CheckboxField";
import Slugger from "../../services/form/Slugger";
import Utils from "../../services/Utils";
import TextInput from "./TextInput";
import {Map} from "immutable"
import {useState} from "react";
import {LocalStorage} from "../../services/form/LocalStorage";

type CheckboxInputProps = {
    label: string;
    name: string;
    options: string[]
}

type CheckboxesInputState = {
    values: Map<string, boolean>
}

const CheckboxesInputStateRepository = {
    default: (name: string): CheckboxesInputState => {

        const optionName = `${name}[options]`

        const storageValue: Map<string, boolean> = LocalStorage.get(optionName) ?
            Map<string, boolean>(JSON.parse(LocalStorage.get(optionName) as string)) :
            (Map<string, boolean>())

        return {values: storageValue}
    },
    updaters: {
        setValue: (name: string, option: string, checked: boolean) => (state: CheckboxesInputState): CheckboxesInputState => {
            const optionName = `${name}[options]`
            const newValues = state.values.set(option, checked)
            LocalStorage.set(optionName, JSON.stringify(newValues))
            return {...state, values: newValues}
        }
    },
    getters: {
        getValueByOption: (state: CheckboxesInputState, option: string): boolean => state.values.get(option) ?? false
    }
}

export default function CheckboxesInput(props: CheckboxInputProps) {

    const [state, setState] = useState<CheckboxesInputState>(CheckboxesInputStateRepository.default(props.name))

    const otherName = `${props.name}[other]`

    return <>
        <div className="mb-4">
            <label className={"text-lobcv-blue-600 text-lg w-full"} htmlFor={props.name}>{props.label}</label>
            <div className="grid grid-cols-4 mb-2">
                {
                    props.options.map((option, index) => (
                        <>
                            <CheckboxField label={Utils.capitalizeFirstLetter(option)}
                                           name={Slugger.slugify(option)}
                                           checked={CheckboxesInputStateRepository.getters
                                               .getValueByOption(state, Slugger.slugify(option))}
                                           value={Slugger.slugify(option)}
                                           onChange={(e) => {
                                               setState(CheckboxesInputStateRepository.updaters
                                                   .setValue(props.name, Slugger.slugify(option), e.currentTarget.checked))
                                           }}
                            />
                        </>
                    ))
                }
            </div>
            <TextInput vertical label={"Anders namelijk"} name={otherName}/>
        </div>
    </>
}

import HorizontalInput from "./layout/HorizontalInput";
import {useState} from "react";
import InputStateType from "../../types/form/InputStateType";
import {LocalStorage} from "../../services/form/LocalStorage";
import VerticalInput from "./layout/VerticalInput";
import TextInputField from "./common/TextInputField";
import TextareaInputField from "./common/TextareaInputField";

type TextInputState = InputStateType

type TextInputProps = {
    label: string;
    name: string;
    vertical?: boolean;
} & (
    | {
    textarea: true;
    date?: never;
}
    | {
    date?: never;
    textarea?: false;
} | {
    date?: true;
    textarea?: false;
}
    );

const TextInputState = {
    default: (name: string): TextInputState => ({value: LocalStorage.get(name)}),
    updaters: {
        setValue: (name: string, value: string) => (state: TextInputState): TextInputState => {
            LocalStorage.set(name, value);
            return {...state, value: value};
        }
    }
}

export default function TextInput(props: TextInputProps) {

    const [state, setState] = useState<TextInputState>(TextInputState.default(props.name));

    return <>
        {
            props.textarea ?

                props.vertical ?
                    <VerticalInput label={props.label} name={props.name}>
                        <TextareaInputField
                            name={props.name}
                            value={state.value ?? ''}
                            onChange={(e) => setState(TextInputState.updaters.setValue(props.name, e.currentTarget.value))}
                        />
                    </VerticalInput> :
                    <HorizontalInput labelTop={true} label={props.label} name={props.name}>
                        <TextareaInputField
                            name={props.name}
                            value={state.value ?? ''}
                            onChange={(e) => setState(TextInputState.updaters.setValue(props.name, e.currentTarget.value))}
                        />
                    </HorizontalInput> :

                props.vertical ?
                    <VerticalInput label={props.label} name={props.name}>
                        <TextInputField
                            name={props.name}
                            value={state.value ?? ''}
                            onChange={(e) => setState(TextInputState.updaters.setValue(props.name, e.currentTarget.value))}
                            type={props.date ? 'date' : 'text'}
                        />
                    </VerticalInput> :
                    <HorizontalInput label={props.label} name={props.name}>
                        <TextInputField
                            name={props.name}
                            value={state.value ?? ''}
                            onChange={(e) => setState(TextInputState.updaters.setValue(props.name, e.currentTarget.value))}
                            type={props.date ? 'date' : 'text'}
                        />
                    </HorizontalInput>

        }
    </>
}

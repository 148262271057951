import React, {useEffect, useState} from "react";
import {LocalStorage} from "../../services/form/LocalStorage";

import {PieChart, Pie, Sector, Cell, ResponsiveContainer} from 'recharts';
import ChartInput from "./ChartInput";

type PieChartValuesType = {
    [key: string]: number
}

type PieChartInputState = {
    values: PieChartValuesType
}

/* TODO update values with saved data */
const PieChartInputState = {
    default: (name: string): PieChartInputState => ({
        values: {
            'Lesuren': parseInt(LocalStorage.get('Lesuren') ?? '0'),
            'Reistijd': parseInt(LocalStorage.get('Reistijd') ?? '0'),
            'Zelfstudie': parseInt(LocalStorage.get('Zelfstudie') ?? '0'),
            'Bijbaantje': parseInt(LocalStorage.get('Bijbaantje') ?? '0'),
            'Ontspanning': parseInt(LocalStorage.get('Ontspanning') ?? '0'),
            'Sporten': parseInt(LocalStorage.get('Sporten') ?? '0'),
            'Huishouden': parseInt(LocalStorage.get('Huishouden') ?? '0'),
            'Zorgtaken': parseInt(LocalStorage.get('Zorgtaken') ?? '0'),
            'Slapen': parseInt(LocalStorage.get('Slapen') ?? '0'),
            'Anders': parseInt(LocalStorage.get('Anders') ?? '0'),
        }
    }),
    updaters: {
        setPieValue: (name: string) => (value: number) => (state: PieChartInputState): PieChartInputState => {
            LocalStorage.set(name, value.toString());
            return {...state, values: {...state.values, [name]: value}};
        }
    }
}

type PieChartInputPropsType = {
    name: string,
    renderOnly?: boolean
}

const input_data = {
    "Lesuren": {color: '#d4f7e8'},
    "Reistijd": {color: '#aceed5'},
    "Zelfstudie": {color: '#76dfbe'},
    "Bijbaantje": {color: '#3ec9a2'},
    "Ontspanning": {color: '#1cb58f'},
    "Sporten": {color: '#0e8d70',},
    "Huishouden": {color: '#0c705c'},
    "Zorgtaken": {color: '#0c5949'},
    "Slapen": {color: '#0b493e'},
    "Anders": {color: '#052924'},
}

const icons = {
    0: {
        svg: "M12 3L1 9l11 6 9-4.91V17h2V9M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82z",
        viewbox: "0 0 25 25",
        fill: "#0b493e"
    },
    1: {
        svg: "M11 7l1.44 2.16c.31.47 1.01.84 1.57.84H17V8h-3l-1.44-2.16a5.94 5.94 0 00-1.4-1.4l-1.32-.88a1.72 1.72 0 00-1.7-.04L4 6v5h2V7l2-1-3 14h2l2.35-7.65L11 14v6h2v-8l-2.7-2.7L11 7zm1-3a2 2 0 100-4 2 2 0 000 4z",
        viewbox: "0 0 25 25", fill: "#0b493e"
    },
    2: {
        svg: "M8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 000 2.5v11a.5.5 0 00.707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 00.78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0016 13.5v-11a.5.5 0 00-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z",
        viewbox: "0 0 25 25", fill: "#0b493e"
    },
    3: {
        svg: "M304 64c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 4 21.5 48 48zM9.8 214.8c5.1-2.2 19.1-18 31.4-12.9l19.5 8.1 22.9-38.1C99.9 144.6 129.3 128 161 128c51.4 0 97 32.9 113.3 81.7l34.6 103.7 79.3 33.1 34.2-45.6c6.4-8.5 16.6-13.3 27.2-12.8s20.3 6.4 25.8 15.5l96 160c5.9 9.9 6.1 22.2.4 32.2S555.5 512 544 512H288c-11.1 0-21.4-5.7-27.2-15.2s-6.4-21.2-1.4-31.1l16-32c5.4-10.8 16.5-17.7 28.6-17.7h32l22.5-30L22.8 246.2c-12.2-5.1-18-19.1-12.9-31.4zm82.8 91.8l112 48c11.8 5 19.4 16.6 19.4 29.4v96c0 17.7-14.3 32-32 32s-32-14.3-32-32v-74.9l-60.6-26-37 111c-5.6 16.8-23.7 25.8-40.5 20.2s-25.8-23.7-20.3-40.4l48-144 11-33 32 13.7z",
        viewbox: "0 0 576 512", fill: "#FFFFFF"
    },
    4: {
        svg: "M272 24c0-13.3-10.7-24-24-24s-24 10.7-24 24v5.2c0 34 14.4 66.4 39.7 89.2l16.4 14.8c15.2 13.7 23.8 33.1 23.8 53.5V200c0 13.3 10.7 24 24 24s24-10.7 24-24v-13.2c0-34-14.4-66.4-39.7-89.2l-16.4-14.8C280.7 69.1 272 49.7 272 29.2V24zM0 320v128c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H277.3c-13.8 0-27.3-4.5-38.4-12.8l-85.3-64C137 166.7 116.8 160 96 160c-53 0-96 43-96 96v64zm128 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16v-96c0-8.8 7.2-16 16-16s16 7.2 16 16zm80-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16v-96c0-8.8 7.2-16 16-16zm112 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16v-96c0-8.8 7.2-16 16-16s16 7.2 16 16zm80-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16v-96c0-8.8 7.2-16 16-16zM360 0c-13.3 0-24 10.7-24 24v5.2c0 34 14.4 66.4 39.7 89.2l16.4 14.8c15.2 13.7 23.8 33.1 23.8 53.5V200c0 13.3 10.7 24 24 24s24-10.7 24-24v-13.2c0-34-14.4-66.4-39.7-89.2l-16.4-14.8C392.7 69.1 384 49.7 384 29.2V24c0-13.3-10.7-24-24-24zM64 128c35.3 0 64-28.7 64-64S99.3 0 64 0 0 28.7 0 64s28.7 64 64 64z",
        viewbox: "0 0 512 512", fill: "#FFFFFF"
    },
    5: {
        svg: "M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm140.64 296.08h-46.77l-16.89-29 15-60.44L377.79 242l42.65 36.71a164.87 164.87 0 01-23.8 65.37zM134.21 242L164 254.67l15 60.44-16.89 29h-46.75a164.87 164.87 0 01-23.8-65.34zm249.07-92.47l-18.41 52.33-31.12 13.18L277 167.46v-35l43.86-29.22a166.87 166.87 0 0162.42 46.32zM191.14 103.2L235 132.42v35l-56.75 47.61-31.12-13.18-18.41-52.33a166.87 166.87 0 0162.42-46.32zm26.44 314.3l-20.1-50.66 16-27.51h85l16.06 27.53-20 50.6a166.23 166.23 0 01-77 0z",
        viewbox: "0 0 512 512", fill: "#FFFFFF"
    },
    6: {
        svg: "M19.36 2.72l1.42 1.42-5.72 5.71c1.07 1.54 1.22 3.39.32 4.59L9.06 8.12c1.2-.9 3.05-.75 4.59.32l5.71-5.72M5.93 17.57c-2.01-2.01-3.24-4.41-3.58-6.65l4.88-2.09 7.44 7.44-2.09 4.88c-2.24-.34-4.64-1.57-6.65-3.58z",
        viewbox: "0 0 25 25", fill: "#FFFFFF"
    },
    7: {
        svg: "M11 2a8.002 8.002 0 017.934 6.965l2.25 3.539c.148.233.118.58-.225.728L19 14.07V17a2 2 0 01-2 2h-1.999L15 22H6v-3.694c0-1.18-.436-2.297-1.244-3.305A8 8 0 0111 2zm-.53 5.763a1.75 1.75 0 10-2.475 2.474L11 13.243l3.005-3.006a1.75 1.75 0 10-2.475-2.474l-.53.53-.53-.53z",
        viewbox: "0 0 25 25", fill: "#FFFFFF"
    },
    8: {
        svg: "M23 12h-6v-2l3.39-4H17V4h6v2l-3.38 4H23v2m-8 4H9v-2l3.39-4H9V8h6v2l-3.38 4H15v2m-8 4H1v-2l3.39-4H1v-2h6v2l-3.38 4H7v2z",
        viewbox: "0 0 25 25", fill: "#FFFFFF"
    },
    9: {
        svg: "M208 32c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32v140.9l122-70.4c15.3-8.8 34.9-3.6 43.7 11.7l16 27.7c8.8 15.3 3.6 34.9-11.7 43.7L352 256l122 70.4c15.3 8.8 20.5 28.4 11.7 43.7l-16 27.7c-8.8 15.3-28.4 20.6-43.7 11.7l-122-70.4V480c0 17.7-14.3 32-32 32h-32c-17.7 0-32-14.3-32-32V339.1L86 409.6c-15.3 8.8-34.9 3.6-43.7-11.7l-16-27.7c-8.8-15.3-3.6-34.9 11.7-43.7L160 256 38 185.6c-15.3-8.8-20.5-28.4-11.7-43.7l16-27.7c8.8-15.4 28.4-20.6 43.7-11.8l122 70.4V32z",
        viewbox: "0 0 512 512", fill: "#FFFFFF"
    },
}

interface pieProps {
    cx: number,
    cy: number,
    midAngle: number,
    innerRadius: number,
    outerRadius: number,
    percent: number,
    index: number,
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}: pieProps) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.78;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <>
            {
                percent > 0 ?
                    <svg
                        x={x - 15}
                        y={y - 15}
                        viewBox={icons[index as keyof typeof icons].viewbox}
                        fill={icons[index as keyof typeof icons].fill}
                        height="40px"
                        width="40px"
                    >
                        <path d={icons[index as keyof typeof icons].svg}/>
                        {/*<text x={x} y={y + 350} fill={icons[index as keyof typeof icons].fill} font-size="100px" textAnchor={x > cx ? 'center' : 'center'} dominantBaseline="central">*/}
                        {/*    {percent > 0 ? `${(percent * 100).toFixed(0)}%` : ''}*/}
                        {/*</text>*/}
                    </svg>

                    : ''
            }

        </>
    );
};


interface dataProps {
    values: object
}

const buildData = (state: dataProps) => {
    return Object.keys(state.values).map((key) => {
        const value = state.values[key as keyof typeof state.values];
        if (value !== 0) {
            return {
                name: key,
                value: state.values[key as keyof typeof state.values],
                color: input_data[key as keyof typeof input_data].color
            }
        }
    })
}

const totalhours = (state: dataProps) => {
    // Count the total hours
    let total = 0;
    Object.keys(state.values).map((key) => {
        total += state.values[key as keyof typeof state.values];
    });
    return total;
}

export default function Chart(props: PieChartInputPropsType) {

    const [state, setState] = useState<PieChartInputState>(PieChartInputState.default(props.name));


    useEffect(() => {

        if(props.renderOnly){
            window.addEventListener('lobcv:export:pdf:start', () => {
                setState(PieChartInputState.default(props.name));
            })
        }

    }, []);

    return <>
        <div className="flex-none lg:flex lg:items-center">
            <div className="flex-none lg:flex-auto">
                {
                    Object.keys(state.values).map((key, index) => {
                        return <ChartInput
                            key={key}
                            label={key}
                            value={state.values[key as keyof typeof state.values]}
                            svg={icons[index as keyof typeof icons].svg}
                            viewbox={icons[index as keyof typeof icons].viewbox}
                            setValue={(value) => {
                                setState(PieChartInputState.updaters.setPieValue(key)(value));
                            }}
                        />
                    })
                }
            </div>

            <div className="flex-none lg:flex-auto">
                <div className="flex justify-center">
                    <PieChart className="my-5" width={600} height={600}>
                        <Pie
                            data={buildData(state)}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={280}
                            fill="#8884d8"
                            dataKey="value"
                            animationDuration={800}
                        >
                            {
                                Object.keys(state.values).map((key) => {
                                    return <Cell key={`cell-${key}`}
                                                 fill={input_data[key as keyof typeof input_data].color}/>
                                })
                            }
                        </Pie>
                    </PieChart>
                </div>
                {totalhours(state) > 168 ?
                    <div className="text-red-500 text-center">Let op! Er zitten maar 168 uren in een week. Je hebt er
                        nu {totalhours(state)} ingevuld.</div> : ''}
            </div>
        </div>
    </>
}

import HorizontalInput from "./layout/HorizontalInput";
import React, {useState} from "react";
import InputStateType from "../../types/form/InputStateType";
import {LocalStorage} from "../../services/form/LocalStorage";
import VerticalInput from "./layout/VerticalInput";
import MultiSelectInputOption from "./MultiSelectInputOption";
import Slugger from "../../services/form/Slugger";

type MultiSelectInputPropsType = {
    label: string,
    name: string,
    options: string[]
}

export default function MultiSelectInput(props: MultiSelectInputPropsType) {

    return <>
        <VerticalInput label={props.label}>
            <>
                <div className="w-full h-full">
                    {
                        props.options.map((option, index) => {
                            return <MultiSelectInputOption label={option} name={`${props.name}[${Slugger.slugify(option)}]`} />
                        })
                    }
                </div>
            </>
        </VerticalInput>
    </>
}

import React from "react";

type ChartInputPropsType = {
    label: string,
    setValue: (value: number) => void,
    value: number,
    svg: string,
    viewbox: string
}

const ChartInput = (props: ChartInputPropsType) => {
    return <>
        <div className={"flex w-full mb-3"}>
            <label className="flex flex-row  text-lobcv-blue-600 text-lg w-full mb-3 pr-5 w-2/3 sm:w-1/3 lg:w-2/4">
                <svg className={"mr-2 max-w-[30px] max-h-[30px]"}
                    viewBox={props.viewbox}
                    fill="currentColor"
                    height="2em"
                    width="2em"
                >
                    <path d={props.svg}/>
                </svg>
                {props.label}
            </label>
            <input type="text" value={props.value}
                   className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent w-1/3 sm:w-1/3 lg:w-1/4 min-h-8"
                   onChange={(e) => props.setValue(isNaN(Number(e.target.value)) !== true ? Number(e.target.value) : 0 )}/>
        </div>
    </>
}

export default ChartInput;
import React, {ReactElement} from "react";

type ChapterListPropsType = {
    children: ReactElement | ReactElement[] | string;
    className?: string;
    listTitle?: string;
    options: string[];
};

export default function ChapterList(props: ChapterListPropsType) {
    return <>

        {props.listTitle && <p className="text-lobcv-blue-600 text-lg font-bold"><strong>{props.listTitle}</strong></p>}
        <ul className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-lobcv-blue-600 list-disc list-inside text-lg mb-8 " + (props.listTitle ? "pl-[20px]" : '') +  ' ' + props.className}>
            {
                props.options.map((option, index) => {
                    return <li key={index} className="" value={option}  dangerouslySetInnerHTML={{ __html: option }}></li>
                })
            }
        </ul>
    </>
}